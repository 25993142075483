import { Copy } from "lucide-react";

import { Button } from "../../../../../components/ui/button";
import { trpc } from "../../../../../config/trpc";

export const DuplicateOligoButton = ({ id }: { id: string }) => {
  const utils = trpc.useUtils();
  const { mutate: duplicateOligo } = trpc.order.duplicateOligo.useMutation({
    onSuccess(newOligo) {
      utils.order.oligos.setData(undefined, (prev) => {
        if (!prev) {
          return undefined;
        }
        const index = prev.findIndex((oligo) => oligo.id === id);
        return [
          ...prev.slice(0, index + 1),
          newOligo,
          ...prev.slice(index + 1),
        ];
      });
    },
  });

  return (
    <Button
      onClick={(e) => {
        duplicateOligo(id);
        e.preventDefault();
        e.stopPropagation();
      }}
      size="xs"
      variant={"ghost"}
    >
      <Copy size="14" />
    </Button>
  );
};
