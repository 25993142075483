import { AccountStatus, Role } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { getQueryKey } from "@trpc/react-query";
import { SaveIcon, Trash, Undo } from "lucide-react";
import { useEffect, useState } from "react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { RolesEditWithChange } from "../../../../components/logic/roles";
import { Button } from "../../../../components/ui/button";
import Combobox from "../../../../components/ui/combobox";
import { Form } from "../../../../components/ui/form";
import { Separator } from "../../../../components/ui/separator";
import { useToast } from "../../../../components/ui/use-toast";
import { queryClient, trpc } from "../../../../config/trpc";
import { AdminRoutes } from "../../adminRoutes";
import { useGetUsers } from "../../users/hooks";
import { useGetOrganizationProfile } from "../hooks";

const addUserToOrganizationForm = z.object({
  roles: z.array(z.nativeEnum(Role)),
  users: z.array(
    z.object({
      email: z.string(),
    }),
  ),
});

type AddUserToOrganizationForm = z.infer<typeof addUserToOrganizationForm>;

export default function OrganizationMembersInvite() {
  const { data: organizationProfileData } = useGetOrganizationProfile();
  const navigate = useNavigate();

  const { organizationId } = useParams();
  const { data } = useGetUsers();
  const { toast } = useToast();
  const [usersNotInOrganization, setUsersNotInOrganization] = useState<
    string[]
  >([]);
  const form = useForm<AddUserToOrganizationForm>({
    defaultValues: {
      roles: [] as Role[],
      users: [],
    },
    resolver: zodResolver(addUserToOrganizationForm),
  });

  const navigateToOrganizationMembers = () => {
    navigate(
      AdminRoutes.ORGANIZATION_MEMBERS.replace(
        ":organizationId",
        organizationProfileData?.id ?? "",
      ),
    );
  };

  useEffect(() => {
    setUsersNotInOrganization(
      (data ?? [])
        .filter(
          (user) =>
            !user.memberships.some(
              (membership) =>
                membership.organizationId === organizationId &&
                membership.status === AccountStatus.ACTIVE,
            ),
        )
        .map((user) => user.email),
    );
  }, [data, organizationId]);

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: "users",
  });

  const { mutate: addToOrganization } =
    trpc.account.organizationUser.addUsersToOrganization.useMutation({
      onError(err) {
        toast({
          description: err.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: [
            getQueryKey(trpc.account.user.list),
            getQueryKey(trpc.account.organization.read, {
              id: organizationId,
            }),
          ],
        });
        navigateToOrganizationMembers();
      },
    });

  function onSubmit(values: AddUserToOrganizationForm) {
    if (!organizationId) {
      return;
    }
    addToOrganization({
      emails: values.users.map((x) => x.email),
      organizationId,
      roles: values.roles,
    });
  }

  const setValues = (newValues: string[]) => {
    form.setValue(
      "users",
      newValues.map((email) => ({ email })),
    );
    setUsersNotInOrganization(
      usersNotInOrganization.filter((email) => !newValues.includes(email)),
    );
  };

  if (!organizationProfileData) {
    throw new Error("Organization not found");
  }

  const currentRoles = form.watch("roles");
  const handleChangeAuthorizations = (roles: Role[]) => {
    if (JSON.stringify(currentRoles) === JSON.stringify(roles)) {
      return;
    }
    form.setValue("roles", roles);
  };

  return (
    <div className="bg-card space-y-2 rounded-lg border p-4">
      <Form {...form}>
        <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex justify-between space-x-2">
            <h3 className="font-bold">Invite Members</h3>
            <div className="flex items-center justify-end space-x-2">
              <Button
                aria-label="add-to-organization"
                className="flex flex-row space-x-1"
                onClick={() => {
                  navigateToOrganizationMembers();
                }}
                type="button"
                variant={"outline"}
              >
                <Undo />
                <span>Cancel</span>
              </Button>
              <Button
                className="flex flex-row space-x-1"
                disabled={fields.length === 0}
                type="submit"
              >
                <SaveIcon />
                <span>Submit</span>
              </Button>
            </div>
          </div>

          <div className="w-[400px]">
            <span>Select users to add to the organization </span>
            <Combobox
              hasCustomColor={false}
              inputTitle="Select users..."
              options={usersNotInOrganization}
              setOptions={setUsersNotInOrganization}
              setValues={setValues}
              values={fields.map((item) => item.email)}
            />
          </div>
          <h3>Users</h3>
          <ul className="space-y-2 rounded-lg border p-2">
            {fields.length === 0 ? (
              <p>No user selected</p>
            ) : (
              fields.map((item, index) => (
                <React.Fragment key={item.id}>
                  <li className="flex flex-row items-center justify-between">
                    <p>{item.email}</p>
                    <Button
                      onClick={() => {
                        setUsersNotInOrganization((prev) =>
                          prev
                            .filter((email) => email !== item.email)
                            .concat(item.email),
                        );
                        remove(index);
                      }}
                      type="button"
                      variant={"outline"}
                    >
                      <Trash />
                    </Button>
                  </li>
                  <Separator />
                </React.Fragment>
              ))
            )}
          </ul>
          <RolesEditWithChange
            currentRoles={currentRoles}
            handleChange={handleChangeAuthorizations}
            possiblesRoles={[Role.OrganizationManager]}
          />
        </form>
      </Form>
    </div>
  );
}
