import { useContext } from "react";

import { trpc } from "../../config/trpc";
import { UserContext } from "../contexts";

export const useGetUIAuthorizedCreations = () => {
  const userContext = useContext(UserContext);

  const {
    canCreateUser,
    canCreateOrganization,
    canCreateOrder,
    canChangeOrder,
  } = userContext?.authorizations ?? {};

  return {
    assay: true,
    construct: true,
    hamiltonInstrument: true,
    instrument: true,
    order: {
      change: canChangeOrder,
      create: canCreateOrder,
    },
    organization: true,
    organizationMember: !!canCreateOrganization,
    plate: true,
    team: true,
    user: !!canCreateUser,
    workflow: true,
  };
};

export const useInvalidateTags = () => {
  const utils = trpc.useUtils();
  return utils.account.user.tags.invalidate;
};

export const useGetOrganizationName = () => {
  const userContext = useContext(UserContext);

  return userContext?.membership?.organization.name;
};

export const useIsServiceEnabled = () => {
  const userContext = useContext(UserContext);

  return Boolean(userContext?.membership?.organization.serviceEnabled);
};
