import { PlateStatus, RunState, StepType } from "@console/shared";
import { PlayCircle, ListChecksIcon, Trash2Icon } from "lucide-react";

import ChooseHamiltonInstrument from "./choose-hamilton-instrument";
import ChooseSyntaxInstrument from "./choose-syntax-instrument";
import DownloadPlateLayout from "./download-plate-layout";
import { GenerateInstructionButton } from "./gene/generate-instructions";
import MarkPlateAsPrinting from "./mark-plate-as-printing";
import type {
  GeneWorkflowStep,
  PlateFromWorkflow,
  SynthesisWorkflowStep,
  WorkflowStepTRPC,
} from "./plate/types";
import UploadSynthesisFile from "./upload-synthesis-file";

import { Button } from "../../../../../components/ui/button";
import { LoadingSpinner } from "../../../../../components/ui/spinner";
import type { AssayTRPC } from "../../../../../config/trpc";
import { useIsServiceEnabled } from "../../../../../containers/user/hooks";
import { useCheckRun } from "../useCheckRun";
import { useDeleteRun } from "../useDeleteRun";
import { useStartRun } from "../useStartJob";

function GeneStepActions({ step }: { step: GeneWorkflowStep }) {
  const hasAssignedHamiltonInstrument = Boolean(step.gene.instrument);

  return (
    <>
      <ChooseHamiltonInstrument step={step} />
      {hasAssignedHamiltonInstrument && (
        <GenerateInstructionButton stepId={step.id} />
      )}
    </>
  );
}

export function PlateActions({
  plate,
  uploadDisabled = false,
}: {
  plate: PlateFromWorkflow;
  uploadDisabled?: boolean;
}) {
  const isRunCreated = Boolean(plate.run);
  const isAssignedToPrototype = Boolean(plate.prototypeName);
  const isQueued = isRunCreated && plate.run?.state === RunState.Queued;
  const isLoaded = isRunCreated && plate.run?.state === RunState.Loaded;
  const isPlateChecked = Boolean(plate.run?.canRun);
  const isServiceEnabled = useIsServiceEnabled();
  const isInWorkflow = Boolean(plate.stepId);
  const runId = plate.run?.id;
  const { startRun, isPending: isStartPending } = useStartRun(runId);
  const { checkRun, isPending: isCheckPending } = useCheckRun(runId);
  const { deleteRun } = useDeleteRun(runId);

  if (plate.status === PlateStatus.Canceled) {
    return null;
  }

  return (
    <div className="flex flex-row space-x-2">
      {!isInWorkflow && isServiceEnabled && !isRunCreated && (
        <>
          <UploadSynthesisFile plate={plate} />
          <MarkPlateAsPrinting id={plate.id} />
          <DownloadPlateLayout id={plate.id} />
        </>
      )}
      {!isAssignedToPrototype && (
        <ChooseSyntaxInstrument disabled={uploadDisabled} plate={plate} />
      )}
      {(isQueued || isLoaded) && (
        <>
          {isPlateChecked ? (
            <Button
              className="flex flex-row items-center space-x-1"
              disabled={isStartPending}
              onClick={startRun}
            >
              <PlayCircle />
              <span>Start run</span>
              {isStartPending && <LoadingSpinner />}
            </Button>
          ) : (
            <Button
              className="flex flex-row items-center space-x-1"
              disabled={isCheckPending}
              onClick={checkRun}
            >
              <ListChecksIcon />
              <span>Check run</span>
              {isCheckPending && <LoadingSpinner />}
            </Button>
          )}
          <Button
            className="flex flex-row items-center space-x-2"
            disabled={isLoaded}
            onClick={deleteRun}
            variant={"outline"}
          >
            <Trash2Icon />
            <span>Remove from queue</span>
          </Button>
        </>
      )}
    </div>
  );
}

function OligoSynthesisStepActions({
  step,
  assay,
}: {
  assay: AssayTRPC | undefined;
  step: SynthesisWorkflowStep;
}) {
  const plate = step.plate;
  const uploadDisabled = Boolean(assay?.archived);
  return <PlateActions plate={plate} uploadDisabled={uploadDisabled} />;
}

export default function StepActions({
  step,
  assay,
}: {
  assay: AssayTRPC | undefined;
  step: WorkflowStepTRPC;
}) {
  if (step.stepType === StepType.HamiltonAssembly) {
    return <GeneStepActions step={step} />;
  }
  if (step.stepType === StepType.OligoSynthesis) {
    return <OligoSynthesisStepActions assay={assay} step={step} />;
  }

  return null;
}
