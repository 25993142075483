import { HardDriveUpload } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../../components/ui/button";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";

export default function CreateNewAssay({
  constructId,
}: {
  constructId: string;
}) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { mutate: createAssay } = trpc.assay.createFromConstruct.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    onSuccess(newAssayId) {
      toast({
        description: "Your assay was created successfully",
        title: "Assay created",
        variant: "default",
      });
      navigate(OrganizationRoutes.ASSAY.replace(":assayId", newAssayId));
    },
  });

  return (
    <div
      className="flex flex-row items-center space-x-1"
      onClick={() => createAssay(constructId)}
    >
      <HardDriveUpload />
      <span>Create assay</span>
    </div>
  );
}

export const CreateNewAssayButton = ({
  constructId,
}: {
  constructId: string;
}) => {
  return (
    <Button variant={"outline"}>
      <CreateNewAssay constructId={constructId} />
    </Button>
  );
};
