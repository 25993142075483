import { OligoStatus } from "@console/shared";
import { ArrowRightToLine, PlusSquare } from "lucide-react";
import { useCallback, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BooleanParam, useQueryParam } from "use-query-params";

import NewPlateMaker from "./components/new-plate-maker";
import { NewPlateAction } from "./components/new-production-plate.reducer";
import OligoRow from "./components/oligo-row";
import { oligosColumns } from "./components/oligos-columns";
import { NewPlateProvider } from "./containers/NewPlateContainer";
import useNewPlateContext from "./hooks/useNewPlateContext";

import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import type { TableRow } from "../../../../components/ui/table";

export default function Oligos() {
  return (
    <NewPlateProvider>
      <OligosInProvider />
    </NewPlateProvider>
  );
}
function OligosInProvider() {
  const [isCreatingPlate, setIsCreatingPlate] = useQueryParam(
    "newplate",
    BooleanParam,
  );
  const {
    newPlate,
    selectedOligos,
    dispatchPlateChange,
    setSelectedOligos,
    oligos,
  } = useNewPlateContext();

  const allSelectedOligosAreUnassignedAndQueued = selectedOligos.every(
    (oligoId) => {
      const oligo = oligos.find((oligo) => oligo.id === oligoId);
      return (
        oligo?.status === OligoStatus.Queued && !oligo?.isCurrentlyAssigned
      );
    },
  );

  const initPlate = useCallback(() => {
    dispatchPlateChange({ type: NewPlateAction.Init });
    setIsCreatingPlate(true);
  }, [dispatchPlateChange, setIsCreatingPlate]);

  useEffect(() => {
    if (isCreatingPlate && !newPlate) {
      initPlate();
    }
  }, [initPlate, isCreatingPlate, newPlate]);

  const cancel = () => {
    dispatchPlateChange({ type: NewPlateAction.Cancel });
    setIsCreatingPlate(false);
  };
  const putOligosOnPlate = () => {
    setSelectedOligos((prev) =>
      prev.filter((id) => !selectedOligos.includes(id)),
    );
    dispatchPlateChange({
      payload: selectedOligos,
      type: NewPlateAction.AddOligos,
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="grid grid-cols-5 gap-4">
        <div className={isCreatingPlate ? "col-span-3" : "col-span-5"}>
          <Card>
            <CardHeader className="flex flex-row items-start justify-between">
              <CardTitle>Oligos</CardTitle>
              {!isCreatingPlate && (
                <div className="justify-end">
                  <Button
                    className="flex space-x-1"
                    onClick={initPlate}
                    type="button"
                  >
                    <span>New synthesis plate</span>
                    <PlusSquare />
                  </Button>
                </div>
              )}
            </CardHeader>
            <CardContent className="space-y-2">
              <DataTable
                RowComponent={OligoRow as typeof TableRow}
                columns={oligosColumns}
                data={oligos ?? []}
                enableRowSelection
                getRowId={(row) => row.id}
                onSelectionChange={(selected) => {
                  setSelectedOligos(selected.map((s) => s.id));
                }}
                tableContainerClassName="[&_td]:p-1 overflow-scroll max-h-[60vh]"
                useBorders={false}
              />
              {Boolean(newPlate) && (
                <Button
                  className="flex flex-row space-x-1"
                  disabled={
                    !allSelectedOligosAreUnassignedAndQueued ||
                    selectedOligos.length === 0
                  }
                  onClick={putOligosOnPlate}
                  variant={"outline"}
                >
                  <span>Put selected oligos on plate</span>
                  <ArrowRightToLine />
                </Button>
              )}
            </CardContent>
          </Card>
        </div>
        {newPlate && (
          <NewPlateMaker
            cancel={cancel}
            dispatcher={dispatchPlateChange}
            newPlate={newPlate}
          />
        )}
      </div>
    </DndProvider>
  );
}
