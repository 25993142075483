import { Trash } from "lucide-react";
import { useDrag, useDrop } from "react-dnd";

import { NewPlateAction } from "./new-production-plate.reducer";

import { Button } from "../../../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { cn } from "../../../../../lib/utils";
import { DragObjects } from "../../build/new-workflow/types";
import useNewPlateContext from "../hooks/useNewPlateContext";

export default function NewPlateWell({
  well,
  index,
}: {
  index: string;
  well: { oligoId: string; wellIndex: string } | undefined;
}) {
  const hasOligo = Boolean(well);
  const {
    getOligoBgColor,
    dispatchPlateChange: dispatcher,
    getOligoById,
  } = useNewPlateContext();
  const oligo = well?.oligoId ? getOligoById(well?.oligoId) : undefined;

  const removeOligo = () => {
    if (!hasOligo) {
      return;
    }
    dispatcher({
      payload: index,
      type: NewPlateAction.RemoveOligo,
    });
  };

  const moveToWell = (from: string, to: string) => {
    dispatcher({
      payload: { from, to },
      type: NewPlateAction.MoveWell,
    });
  };

  const assignOligo = (oligoId: string) => {
    dispatcher({
      payload: {
        oligoId,
        wellIndex: index,
      },
      type: NewPlateAction.AssignOligo,
    });
  };

  const assignOligos = (oligoIds: string[]) => {
    dispatcher({
      payload: {
        oligoIds,
        wellIndex: index,
      },
      type: NewPlateAction.AssignOligos,
    });
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DragObjects.Well, DragObjects.Oligo],
      canDrop: () => !hasOligo,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      drop: (
        item: { index: string } | { oligoId: string } | { oligoIds: string[] },
      ) => {
        if ("oligoIds" in item) {
          assignOligos(item.oligoIds);
          return;
        }
        if ("oligoId" in item) {
          assignOligo(item.oligoId);
          return;
        }
        moveToWell(item.index, index);
      },
    }),
    [hasOligo],
  );

  const [collected, drag] = useDrag(
    () => ({
      canDrag: () => hasOligo,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: () => {
        return { index };
      },
      type: DragObjects.Well,
    }),
    [hasOligo, index],
  );

  const onMouseEnter = () => {
    if (!well?.oligoId) {
      return;
    }
    document
      .querySelectorAll(`[data-group='${well.oligoId}']`)
      .forEach((el) => {
        el.classList.add("ring");
      });
  };

  const onMouseLeave = () => {
    if (!well?.oligoId) {
      return;
    }
    document
      .querySelectorAll(`[data-group='${well.oligoId}']`)
      .forEach((el) => {
        el.classList.remove("ring");
      });
  };

  return (
    <Tooltip delayDuration={50}>
      <TooltipTrigger disabled={!hasOligo}>
        <div
          className={cn(
            "group relative flex aspect-square items-center justify-center border hover:ring",
            "rounded-full",
            isOver && "ring",
            well && "text-black",
            collected.isDragging && "bg-orange-200",
          )}
          data-group={well?.oligoId}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={(node) => {
            drop(node);
            drag(node);
          }}
          style={{
            backgroundColor: well?.oligoId
              ? getOligoBgColor(well?.oligoId) ?? ""
              : "",
          }}
        >
          {well && !collected.isDragging && (
            <Button
              className="absolute -right-3 -top-3 h-fit p-1 text-black opacity-0 group-hover:opacity-100"
              onClick={removeOligo}
              variant={"outline"}
            >
              <Trash size={14} />
            </Button>
          )}
          <p>{index}</p>
        </div>
      </TooltipTrigger>
      {oligo && (
        <TooltipContent className="grid grid-cols-3 gap-1 text-xs">
          <span className="italic">Name</span>
          <span className="col-span-2">{oligo.name}</span>
          <span className="italic">Length</span>
          <span className="col-span-2">{oligo.size}</span>
          <span className="italic">Order #</span>
          <span className="col-span-2">{oligo.orderSOId}</span>
        </TooltipContent>
      )}
    </Tooltip>
  );
}
