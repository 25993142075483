import { Heading } from "@radix-ui/themes";
import { useEffect } from "react";

import { OrganizationContext } from "./contexts";
import { useAuth, useUser } from "./hooks";

import { trpc } from "../config/trpc";

const OrganizationProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const { token } = useAuth();
  const user = useUser();

  const { data, refetch, isPending } =
    trpc.account.organization.readMine.useQuery(undefined, {
      enabled: Boolean(user?.membership),
    });

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  if (!token) {
    return <Heading>Waiting for authentication...</Heading>;
  }

  if (isPending) {
    return <Heading>Loading organization...</Heading>;
  }

  return (
    <OrganizationContext.Provider value={data}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
