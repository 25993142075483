import { OrganizationStatus } from "@console/shared";
import { getQueryKey } from "@trpc/react-query";
import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  OrganizationEditConfiguration,
  organizationFormSchema,
} from "./components/organization-edit-configuration";

import { useToast } from "../../../../components/ui/use-toast";
import { queryClient, trpc } from "../../../../config/trpc";
import type { OrganizationProfile } from "../hooks";
import { useGetOrganizationProfile } from "../hooks";

const getDefaultValues = (data: OrganizationProfile | undefined) =>
  organizationFormSchema.parse({
    bioSecurityEnabled: data?.configuration.bioSecurityEnabled ?? false,
    clicksEnabled: data?.configuration.clicksEnabled ?? false,
    customProcessEnabled: data?.configuration.customProcessEnabled ?? false,
    degenerateBasesEnabled: data?.configuration.degenerateBasesEnabled ?? false,
    geneEnabled: data?.configuration.geneEnabled ?? false,
    isActive: data?.isActive ?? false,
    kitConfiguration: (data?.configuration.kits ?? []).map((kit) => ({
      ...kit,
      processes: kit.allowedProcesses.map((process) => process.process),
    })),
    mfaMandatory: data?.configuration.mfaMandatory ?? false,
    modsEnabled: data?.configuration.modsEnabled ?? false,
    name: data?.name ?? "",
    notificationsEnabled: data?.configuration.notificationsEnabled ?? false,
    remoteRunTriggerEnabled:
      data?.configuration.remoteRunTriggerEnabled ?? false,
    serviceEnabled: data?.configuration.serviceEnabled ?? false,
  });

export default function OrganizationSettingsEdit() {
  const { data, isPending } = useGetOrganizationProfile();
  const { organizationId } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const locationSettings = location.pathname.split("/").slice(0, -1).join("/");
  const defaultValues = useMemo(() => getDefaultValues(data), [data]);

  const { mutate: updateOrganization } =
    trpc.account.organization.update.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error creating organization",
          variant: "destructive",
        });
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.account.organization.read, {
            id: organizationId,
          }),
        });
        navigate(locationSettings);
      },
    });

  if (isPending) {
    return null;
  }

  if (!data) {
    throw new Error("Organization not found");
  }

  return (
    <OrganizationEditConfiguration
      defaultValues={defaultValues}
      handleSubmit={(values) => {
        updateOrganization({
          bioSecurityEnabled: values.bioSecurityEnabled,
          clicksEnabled: values.clicksEnabled,
          customProcessEnabled: values.customProcessEnabled,
          degenerateBasesEnabled: values.degenerateBasesEnabled,
          geneEnabled: values.geneEnabled,
          id: organizationId ?? "",
          kits: values.kitConfiguration,
          mfaMandatory: values.mfaMandatory,
          modsEnabled: values.modsEnabled,
          name: values.name,
          notificationsEnabled: values.notificationsEnabled,
          remoteRunTriggerEnabled: values.remoteRunTriggerEnabled,
          serviceEnabled: values.serviceEnabled,
          status: values.isActive
            ? OrganizationStatus.ACTIVE
            : OrganizationStatus.INACTIVE,
        });
      }}
      onCancel={() => navigate(locationSettings)}
    />
  );
}
