import { ReagentGroups, ReagentGroupToTitle } from "@console/shared";
import { Info } from "lucide-react";
import { useEffect, useState } from "react";

import type { Reagent } from "./components/plate/types";
import {
  RackTrayBottlesSettings,
  getReagentColorsFromGroup,
  RackHeightReference,
  RackWidthReference,
} from "./reagents";

import {
  CardContainer,
  CardBody,
  CardItem,
  useMouseEnter,
} from "../../../../components/ui/3d-card";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { cn } from "../../../../lib/utils";
import { roundNumber } from "../../../../utils/number";

const SelectItem = ({
  reagent,
  setHoveredReagent,
}: {
  reagent: Reagent | undefined;
  setHoveredReagent: (reagent: Reagent | null) => void;
}) => {
  const [isMouseEntered] = useMouseEnter();

  useEffect(() => {
    if (isMouseEntered && reagent) {
      setHoveredReagent(reagent);
    } else {
      setHoveredReagent(null);
    }
  }, [isMouseEntered, reagent, setHoveredReagent]);

  return null;
};

function TrayBottle({
  reagent,
  isSelected,
  settings,
  position,
  setHoveredReagent,
  setSelectedReagent,
  containerClassName,
  size,
}: {
  containerClassName?: string;
  isSelected: boolean;
  position: string;
  reagent?: Reagent;
  setHoveredReagent: (reagent: Reagent | null) => void;
  setSelectedReagent: (reagent: Reagent | null) => void;
  settings?:
    | {
        height: number;
        left: number;
        top: number;
        width: number;
      }
    | undefined;
  size?: {
    height: number;
    width: number;
  };
}) {
  const reagentColors = getReagentColorsFromGroup(reagent?.group || "");
  return (
    <CardContainer
      className={cn("flex h-full w-full")}
      containerClassName={cn("absolute h-full py-0", containerClassName)}
      handleClick={() => {
        if (reagent) {
          setSelectedReagent(isSelected ? null : reagent);
        }
      }}
      key={position}
      style={
        settings
          ? {
              height: `${(settings.height / RackHeightReference) * 100}%`,
              left: `${(settings.left / RackWidthReference) * 100}%`,
              top: `${(settings.top / RackHeightReference) * 100}%`,
              width: `${(settings.width / RackWidthReference) * 100}%`,
            }
          : {
              height: size?.height || "100%",
              width: size?.width || "100%",
            }
      }
    >
      <CardBody
        className={cn(
          "group/card relative grid h-full w-full cursor-pointer grid-rows-3 rounded-xl border-2 border-black/[0.1] bg-slate-300 shadow-lg hover:border-black",
          isSelected && "border-black",
        )}
      >
        <SelectItem reagent={reagent} setHoveredReagent={setHoveredReagent} />
        <CardItem
          className={cn("absolute rounded-xl shadow-lg", reagentColors.bg)}
          style={{
            height: "90%",
            left: "5%",
            top: "5%",
            width: "90%",
          }}
          translateZ="50"
        />
        <CardItem
          className="row-span-1 flex w-full grow items-start"
          translateZ="50"
        />
        <CardItem
          className={cn(
            "row-span-1 w-full place-content-center text-center text-xl",
            reagentColors.text,
          )}
          translateZ="100"
        >
          <span>{reagent ? reagent.displayName : ""}</span>
        </CardItem>
        <div className="grow basis-0" />
      </CardBody>
    </CardContainer>
  );
}

export default function RunReagentsRack({ reagents }: { reagents: Reagent[] }) {
  const [hoveredReagent, setHoveredReagent] = useState<Reagent | null>(null);
  const [selectedReagent, setSelectedReagent] = useState<Reagent | null>(null);

  const displayedReagent = hoveredReagent || selectedReagent;
  const isDisplayedReagentWash = displayedReagent?.group === ReagentGroups.Wash;

  const h2oReagent = reagents.find((r) => r.position === 17);
  const isReagentSelected = (reagent: Reagent | undefined) =>
    Boolean(reagent && selectedReagent?.name === reagent.name);

  return (
    <div className="flex flex-col p-6">
      <h3 className="flex flex-row items-center space-x-2 font-bold">
        <span>Reagents</span>
        <Tooltip>
          <TooltipTrigger>
            <Info size={16} />
          </TooltipTrigger>
          <TooltipContent>
            <span className="font-medium">
              Click or hover a reagent to see more details
            </span>
          </TooltipContent>
        </Tooltip>
      </h3>
      <div className="grid grid-cols-4 gap-2">
        <div className="bg-background relative col-span-3 aspect-[720/312] rounded-lg border">
          {Object.entries(RackTrayBottlesSettings).map(
            ([position, settings]) => {
              const reagent = reagents.find(
                (r) =>
                  Number.isInteger(r.position) &&
                  r.position === Number(position) &&
                  !r.isAccessory,
              );
              const isSelected = isReagentSelected(reagent);
              return (
                <TrayBottle
                  isSelected={isSelected}
                  key={position}
                  position={position}
                  reagent={reagent}
                  setHoveredReagent={setHoveredReagent}
                  setSelectedReagent={setSelectedReagent}
                  settings={settings}
                />
              );
            },
          )}
        </div>
        {displayedReagent && (
          <div className="col-span-1 flex flex-col space-y-1 p-2">
            <p className="flex flex-row justify-between">
              <span className="italic">Group</span>
              <span>
                {ReagentGroupToTitle[displayedReagent.group || "Unknown"]}
              </span>
            </p>
            <p className="flex flex-row justify-between">
              <span className="italic">Name</span>
              <span>{displayedReagent.displayName}</span>
            </p>
            <p className="flex flex-row justify-between">
              <span className="italic">Part #</span>
              <span>{displayedReagent.partNumber || "N/A"}</span>
            </p>
            <p className="flex flex-row justify-between">
              <span className="italic">Lot #</span>
              <span>{displayedReagent.lotNumber || "N/A"}</span>
            </p>
            <p className="flex flex-row justify-between">
              <span className="italic">Expiration date</span>
              <span>
                {isDisplayedReagentWash
                  ? "N/A"
                  : new Date(
                      displayedReagent.expirationDate,
                    ).toLocaleDateString()}
              </span>
            </p>
            <p className="flex flex-row justify-between">
              <span className="italic">Days on system</span>
              <span>{displayedReagent.daysOnSystem}</span>
            </p>
            <p className="flex flex-row justify-between">
              <span className="italic">Amount used (mL)</span>
              <span>{roundNumber(displayedReagent.amountUsed, 2)}</span>
            </p>
            {displayedReagent.additives.length > 0 && (
              <div>
                <hr className="my-2" />
                <p className="font-bold">Additives</p>
                <ul>
                  {displayedReagent.additives.map((additive, index) => (
                    <li key={additive.name}>
                      <p className="flex flex-row justify-between">
                        <span className="italic">Name</span>
                        <span>{additive.displayName}</span>
                      </p>
                      <p className="flex flex-row justify-between">
                        <span className="italic">Part #</span>
                        <span>{additive.partNumber || "N/A"}</span>
                      </p>
                      <p className="flex flex-row justify-between">
                        <span className="italic">Lot #</span>
                        <span>{additive.lotNumber || "N/A"}</span>
                      </p>
                      <p className="flex flex-row justify-between">
                        <span className="italic">Expiration date</span>
                        <span>
                          {new Date(
                            additive.expirationDate,
                          ).toLocaleDateString()}
                        </span>
                      </p>
                      {index !== displayedReagent.additives.length - 1 && (
                        <hr className="my-2" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {h2oReagent && (
          <>
            <div className="col-span-3 flex justify-end">
              <div className="bg-background flex flex-col space-y-2 rounded-lg border p-4 text-center">
                <p>Vacuum Cabinet</p>
                <TrayBottle
                  containerClassName="relative"
                  isSelected={isReagentSelected(h2oReagent)}
                  position={"17"}
                  reagent={h2oReagent}
                  setHoveredReagent={setHoveredReagent}
                  setSelectedReagent={setSelectedReagent}
                  size={{
                    height: 122,
                    width: 122,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
