import { ArrowUpFromLineIcon, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../../components/ui/button";
import { TableActions } from "../../../../../components/ui/data-table/data-table-actions";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../../containers/user/hooks";
import { OrganizationRoutes } from "../../../organization-routes";
import type { ConstructListFromTRPC } from "../../construct/constructTypes";

const ExportToLibrary = ({ row }: { row: ConstructListFromTRPC }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { construct: canCreateConstruct } = useGetUIAuthorizedCreations();

  const { mutate: exportToLibrary } =
    trpc.construct.exportToLibrary.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          description: (
            <div className="space-x-1">
              <Button
                className="h-100 inline-block"
                disabled={!canCreateConstruct}
                onClick={() => {
                  navigate(OrganizationRoutes.LIBRARY);
                }}
                variant={"ghost"}
              >
                <span>Construct </span>
                <span className="italic">{row.name}</span>
                <span> exported to library of constructs</span>
              </Button>
            </div>
          ),
          title: "Construct exported",
          variant: "default",
        });
      },
    });

  const handleExport = () => {
    exportToLibrary({
      constructId: row.id,
    });
  };

  return (
    <div
      className="flex w-full cursor-pointer flex-row items-center gap-2"
      onClick={handleExport}
    >
      <ArrowUpFromLineIcon /> Export to library of constructs
    </div>
  );
};

export const AssayConstructRowActions = ({
  row,
}: {
  row: ConstructListFromTRPC;
}) => {
  const utils = trpc.useUtils();
  const { toast } = useToast();
  const { assayId, id: constructId } = row;
  const { mutate: removeConstruct } = trpc.construct.delete.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    onSuccess() {
      if (!assayId) {
        return;
      }
      utils.assay.get.invalidate(assayId);
      utils.assay.oligos.invalidate(assayId);
      utils.construct.listInAssay.invalidate({ assayId });
    },
  });

  const handleDeleteConstructInAssay = () => {
    if (!assayId) {
      return;
    }
    removeConstruct({
      constructId,
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message:
              "Are you sure you want to remove this construct ? This action cannot be undone.",
            title: "Remove construct",
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash2 /> Remove construct
            </div>
          ),
          disabled: row.hasOneOligoLocked || row.isUsedInPlate,
          id: "remove",

          onClick: handleDeleteConstructInAssay,
          skip: row.isArchived,
        },
        {
          children: <ExportToLibrary key="export" row={row} />,
          id: "export",
        },
      ]}
    />
  );
};
