import type { PlateSize } from "@console/shared";
import { Role } from "@console/shared";
import type { inferProcedureOutput } from "@trpc/server";
import { useContext, useMemo } from "react";

import type { AppRouter } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";
import { OrganizationContext } from "../../../containers/contexts";
import { useUser } from "../../../containers/hooks";
import { useIsServiceEnabled } from "../../../containers/user/hooks";

export type OrganizationMemberInOrganization = inferProcedureOutput<
  AppRouter["account"]["organization"]["listMembers"]
>[0];

export const useGetMyOrganization = () => {
  return useContext(OrganizationContext);
};

export const useGetAvailableKits = () => {
  const data = useGetMyOrganization();
  return useMemo(
    () => data?.configuration.kits.map((k) => k.kit) ?? [],
    [data?.configuration.kits],
  );
};

export const useKitIdsToSizeMap = () => {
  const data = useGetMyOrganization();
  const kits = data?.configuration.kits ?? [];
  return new Map<string, PlateSize>(kits.map((k) => [k.kit, k.size]));
};

export const useGetModifiers = (useModifiers: boolean) => {
  const { data: modifiers } = trpc.config.modifiers.useQuery(undefined, {
    enabled: useModifiers,
    initialData: [],
  });
  return modifiers;
};

export const useGetMembersOfOrganization = (organizationId: string) => {
  const { data: members } = trpc.account.organization.listMembers.useQuery(
    { organizationId },
    {
      enabled: Boolean(organizationId),
      initialData: [],
    },
  );
  return members;
};

export const useGetOrganizationConfiguration = () => {
  const data = useGetMyOrganization();
  return data?.configuration;
};

export const useIsGeneEnabled = (): boolean => {
  const configuration = useGetOrganizationConfiguration();
  return Boolean(configuration?.geneEnabled);
};

export const useIsRemoteRunEnabled = (): boolean => {
  const configuration = useGetOrganizationConfiguration();
  return Boolean(configuration?.remoteRunTriggerEnabled);
};

export const useCanInviteUser = (): boolean => {
  const user = useUser();
  return Boolean(user?.authorizations.canCreateUser);
};

export const useIsActiveSupportOfOrganization = (): boolean => {
  const user = useUser();
  return Boolean(user?.membership?.isSupport);
};

export function useGetOrgMemberPossibleRoles() {
  const isService = useIsServiceEnabled();
  if (isService) {
    return [
      Role.OrganizationManager,
      Role.ProductionManager,
      Role.OrderCreator,
    ];
  }
  return [Role.OrganizationManager];
}
