import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Link } from "react-router-dom";

import { DuplicateOligoButton } from "./oligo-column-components";
import { OligoStatusComponent } from "./oligo-status";

import { SelectColumn } from "../../../../../components/logic/select-column";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../../components/ui/data-table/filters";
import { type AppRouter } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";

export type OligoFromList = inferProcedureOutput<
  AppRouter["order"]["oligos"]
>[number];

export const oligosColumns: ColumnDef<OligoFromList>[] = [
  SelectColumn(() => null),
  {
    accessorKey: "orderSOId",
    cell: ({ row }) => (
      <Link
        className="text-black underline"
        to={OrganizationRoutes.SERVICE_ORDER.replace(
          ":orderId",
          row.original.orderId,
        )}
      >
        #{row.original.orderSOId}
      </Link>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "orderSOId",
    meta: {
      title: "Order #",
    },
  },
  {
    accessorKey: "plate.id",
    cell: ({ row }) =>
      row.original.plate ? (
        <Link
          className="text-black underline"
          to={OrganizationRoutes.SERVICE_PLATE.replace(
            ":plateId",
            row.original.plate.id,
          )}
        >
          {row.original.plate.name}
        </Link>
      ) : null,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "plateId",
    meta: {
      title: "Plate",
    },
  },
  {
    accessorKey: "status",
    cell: ({ row }) => OligoStatusComponent[row.original.status],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "status",
    meta: {
      title: "Status",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "name",
    cell: ({ row }) => row.original.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "size",
    cell: ({ row }) => row.original.size,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "size",
    meta: {
      title: "Size (nts)",
    },
  },
  {
    cell: ({ row }) => <DuplicateOligoButton id={row.original.id} />,
    header: "Actions",
    id: "actions",
  },
];
