import { Role } from "@console/shared";

export const RoleDescriptions: Record<Role, string> = {
  [Role.Administrator]:
    "Global admin, can invite users, create and manage organizations",
  [Role.ProductionManager]: "Able to manage orders",
  [Role.OrderCreator]: "Able to create orders",
  [Role.OrganizationManager]: "Has access to any content",
  [Role.Support]: "Can support other organizations, for FSEs",
};
