import { Role } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { Heading, Text } from "@radix-ui/themes";
import { getQueryKey } from "@trpc/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { addToTeamColumns } from "./components/add-to-team";
import {
  useGetMembersOfOrganization,
  useGetMyOrganization,
  useGetOrgMemberPossibleRoles,
} from "./hooks";
import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import { RolesEditWithChange } from "../../../components/logic/roles";
import { Button } from "../../../components/ui/button";
import { DataTable } from "../../../components/ui/data-table/data-table";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { queryClient, trpc } from "../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../containers/user/hooks";

const newTeamForm = z.object({
  name: z.string().trim().min(1),
  roles: z.array(z.nativeEnum(Role)),
  users: z.array(
    z.object({
      memberId: z.string(),
    }),
  ),
});

type NewTeamForm = z.infer<typeof newTeamForm>;

export default function NewTeam() {
  const navigate = useNavigate();
  const data = useGetMyOrganization();
  const members = useGetMembersOfOrganization(data?.id ?? "");
  const { team: canAddTeam } = useGetUIAuthorizedCreations();

  const form = useForm<NewTeamForm>({
    defaultValues: {
      name: "",
      roles: [],
      users: [],
    },
    resolver: zodResolver(newTeamForm),
  });

  const { mutate: createTeam } = trpc.account.team.create.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.account.team.list),
      });
      navigate(OrganizationSettingsRoutes.TEAMS);
    },
  });

  const onSubmit = (data: NewTeamForm) => {
    createTeam({
      name: data.name,
      organizationMemberIds: data.users.map((user) => user.memberId),
      roles: data.roles,
    });
  };

  const currentRoles = form.watch("roles");
  const handleChangeRoles = (values: Role[]) => {
    if (JSON.stringify(currentRoles) === JSON.stringify(values)) {
      return;
    }
    form.setValue("roles", values);
  };

  const possiblesRoles = useGetOrgMemberPossibleRoles();

  if (!canAddTeam) {
    navigate(OrganizationSettingsRoutes.TEAMS);
    return null;
  }

  return (
    <div className="space-y-8">
      <Heading>New Team</Heading>
      <Form {...form}>
        <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="bg-card space-y-2 rounded-lg border p-4">
            <Text size="4">Details</Text>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <div className="grid grid-cols-2 gap-10">
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <div>
                      <FormLabel>Name</FormLabel>
                      <FormDescription>Name of the team</FormDescription>
                    </div>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="bg-card space-y-2 rounded-lg border p-4">
            <Text size="4">Permissions</Text>
            <RolesEditWithChange
              currentRoles={currentRoles}
              handleChange={handleChangeRoles}
              possiblesRoles={possiblesRoles}
            />
          </div>
          <div className="bg-card space-y-2 rounded-lg border p-4">
            <Text size="4">Users</Text>
            <DataTable
              columns={addToTeamColumns}
              data={members}
              onSelectionChange={(users) => {
                form.setValue(
                  "users",
                  users.map((x) => ({
                    memberId: members[parseInt(x.id)].id,
                  })),
                );
              }}
            />
          </div>
          <div className="flex justify-end">
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
