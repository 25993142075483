import type { Sequence, SequenceShippingFormat } from "@console/shared";
import { sequenceColumnId } from "@console/shared";
import type { ColumnDef } from "@tanstack/react-table";

import {
  RemoveSequenceFromForm,
  SequenceInfos,
  ShippingFormat,
} from "./table-components";

import { DataTableColumnHeader } from "../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../components/ui/data-table/filters";
import { SequenceInputForCustomers } from "../organization/pages/construct/components/sequence-input";
import { EditableCell } from "../organization/pages/construct/components/useGetOligoColumns";

export const useGetFormSequencesColumns = (
  useGene: boolean,
): ColumnDef<Sequence>[] => {
  const baseColumns: ColumnDef<Sequence>[] = [
    {
      accessorKey: "name",
      cell: (info) => (
        // @ts-expect-error Hack
        <EditableCell cell={info} initialValue={info.row.original.name} />
      ),
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "name",
      meta: {
        title: "Name",
      },
      size: 180,
    },
    {
      accessorKey: "sequence",
      cell: (info) => (
        <div>
          <EditableCell
            InputComponent={SequenceInputForCustomers}
            // @ts-expect-error Hack
            cell={info}
            initialValue={info.row.original.sequence}
          />
        </div>
      ),
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: sequenceColumnId,
      meta: {
        title: "Sequence",
      },
      size: 600,
    },
    {
      accessorKey: "format",
      cell: (info) => (
        <ShippingFormat
          format={info.row.original.format}
          handleChangeFormat={(format: SequenceShippingFormat) => {
            info.table.options.meta?.updateData?.(
              info.row.index,
              "format",
              format,
            );
          }}
          index={info.row.index}
          useGene={useGene}
        />
      ),
      filterFn: arrIncludesSomeWithEmptyFn,
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "format",
      meta: {
        title: "Format",
        uniqueFilter: true,
      },
    },
    // {
    //   accessorKey: "additionalServices",
    //   cell: (info) => (
    //     <AdditionalServices index={info.row.index} useGene={useGene} />
    //   ),
    //   header: "Additional Services",
    //   id: "additionalServices",
    // },
    {
      accessorKey: "infos",
      cell: ({ row }) => {
        return <SequenceInfos index={row.index} useGene={useGene} />;
      },
      header: "Infos",
      id: "infos",
    },
    {
      accessorKey: "remove",
      cell: ({ row }) => {
        return <RemoveSequenceFromForm index={row.index} useGene={useGene} />;
      },
      header: "Actions",
      id: "actions",
    },
  ];

  return baseColumns;
};
