import { Button } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../containers/hooks";
import { useIsServiceEnabled } from "../../../containers/user/hooks";
import { getRouteToGoToAfterLogin } from "../../../utils/auth";

export default function Logo({
  scriptColor = "#ffffff",
}: {
  scriptColor?: string;
}) {
  const navigate = useNavigate();
  const { parsedToken } = useAuth();
  const isServiceEnabled = useIsServiceEnabled();

  return (
    <Button
      onClick={() => {
        navigate(getRouteToGoToAfterLogin(parsedToken));
      }}
      variant="ghost"
    >
      <svg
        height="26"
        viewBox="0 0 234 26"
        width="234"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path d="M.52.031h24.908v24.372H.52z" id="a"></path>
          <path d="M0 .859h25.959V25.23H0z" id="c"></path>
          <path d="M.09.335h25.717V26H.089z" id="e"></path>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M54.832 1h-2.664c-.093 0-.168.092-.168.203v18.594c0 .111.075.203.168.203h2.664c.092 0 .168-.092.168-.203V1.203c0-.111-.076-.203-.168-.203M32.832 6h-2.664c-.093 0-.168.09-.168.203v18.594c0 .113.075.203.168.203h2.664c.092 0 .168-.09.168-.203V6.203C33 6.09 32.924 6 32.832 6"
            fill="rgb(255, 88, 67)"
          ></path>
          <path
            d="M54.798 21.56h-3.697c-1.04 0-2.01-.523-2.532-1.365-1.339-2.16-9.966-15.143-10.057-15.28C37.42 3.12 35.37 2.003 33.162 2h-3.96c-.112 0-.202.085-.202.19V5.25c0 .105.09.19.202.19h3.665c1.039 0 2.008.522 2.53 1.366 1.34 2.158 9.967 15.143 10.057 15.279C46.547 23.883 48.598 25 50.804 25h3.994c.112 0 .202-.085.202-.19V21.75c0-.105-.09-.19-.202-.19"
            fill="rgb(255, 88, 67)"
          ></path>
          <path
            d="M177.831 1h-2.66c-.094 0-.171.09-.171.203v24.593c0 .112.077.204.17.204h2.661c.093 0 .169-.092.169-.204V1.203c0-.112-.076-.203-.169-.203M198.815 13.886h-10.142a.2.2 0 0 1-.2-.203V4.508c0-.112.088-.204.2-.204h10.142c3.929 0 5.605 1.319 5.605 4.404 0 3.29-2.043 5.178-5.605 5.178M198.997 1H185.2a.201.201 0 0 0-.201.203v24.594c0 .112.09.203.201.203h3.071c.11 0 .201-.09.201-.203V17.32a.2.2 0 0 1 .2-.202h10.324c5.722 0 9.003-3.064 9.003-8.409C208 3.448 205.14 1 198.997 1"
            fill={scriptColor}
          ></path>
          <g transform="translate(208 1)">
            <path
              d="M25.23.031H.719A.198.198 0 0 0 .52.229v2.825c0 .11.089.198.198.198h10.305c.109 0 .197.088.197.199v20.753c0 .11.09.2.198.2h3.111c.111 0 .198-.09.198-.2V3.451c0-.111.09-.2.2-.2H25.23a.199.199 0 0 0 .197-.197V.229c0-.11-.09-.198-.197-.198"
              fill={scriptColor}
              mask="url(#b)"
            ></path>
          </g>
          <path
            d="M149.763 13.886a.204.204 0 0 1-.205-.203V4.508c0-.112.092-.204.205-.204h11.173c4.079 0 5.82 1.319 5.82 4.404 0 3.29-2.122 5.178-5.82 5.178h-11.173zm14.68 3.149c-.089-.114-.026-.278.114-.315 3.792-.996 5.869-3.821 5.869-8.012 0-5.26-2.934-7.708-9.23-7.708h-14.991a.204.204 0 0 0-.205.203v24.594c0 .112.092.203.205.203h3.148c.114 0 .205-.09.205-.203V17.32c0-.112.092-.202.205-.202h10.443c.097 0 .188.046.247.124l6.377 8.635a.306.306 0 0 0 .248.124h3.716c.17 0 .265-.19.165-.325l-6.517-8.64z"
            fill={scriptColor}
          ></path>
          <g>
            <path
              d="M22.453 13.742c0 5.693-3.124 8.232-10.129 8.232H3.667a.196.196 0 0 1-.195-.198V4.315c0-.11.087-.2.195-.2h8.657c6.817 0 10.129 2.682 10.129 8.196v1.431zM12.324.86H.195A.197.197 0 0 0 0 1.058v23.975c0 .11.087.198.195.198h12.13c9.174 0 13.634-3.758 13.634-11.489v-1.431c0-7.6-4.587-11.452-13.635-11.452z"
              fill="rgb(255, 88, 67)"
              mask="url(#d)"
            ></path>
          </g>
          <path
            d="M139.816 1.929C138.4 1.342 134.973 0 129.926 0 119.571 0 115 5.593 115 13c0 7.409 4.572 13 14.925 13 4.991 0 8.442-1.35 9.891-1.95a.303.303 0 0 0 .184-.28v-3.196c0-.14-.14-.238-.268-.185-1.075.44-5.243 2.079-9.807 2.079-8.305 0-11.455-4.018-11.455-9.469 0-5.449 3.15-9.467 11.455-9.467 4.74 0 8.75 1.631 9.804 2.08.128.057.271-.042.271-.184V2.209a.3.3 0 0 0-.184-.28"
            fill={scriptColor}
          ></path>
          <g transform="translate(88)">
            <path
              d="M16.4 11.738l-6.76-.68c-4.393-.46-6.027-1.448-6.027-3.64 0-1.88 1.324-2.953 4.307-3.587 1.32-.281 3.001-.486 5.555-.352 3.872.204 7.124 1.035 10.06 2.366a.196.196 0 0 0 .277-.182V2.877a.37.37 0 0 0-.216-.34c-2.268-.99-6.43-2.064-10.19-2.182C9.866.245 8.18.588 6.428.988 2.185 1.955.09 4.103.09 7.491c0 3.963 2.892 6.166 8.84 6.734l7.574.751c4.267.46 5.78 1.375 5.78 3.496 0 1.69-1.564 4.318-8.98 4.318-5.99 0-10.491-1.754-12.347-2.617a.198.198 0 0 0-.28.183v3.011c0 .153.09.288.228.345C4 24.96 8.8 26.088 13.443 25.995c8.003-.163 12.364-2.82 12.364-7.738 0-4.721-3.76-6.021-9.407-6.519"
              fill={scriptColor}
              mask="url(#f)"
            ></path>
          </g>
          <path
            d="M77.523 17.297H65.476c-.142 0-.236-.153-.176-.287l6.007-13.253a.19.19 0 0 1 .351 0L77.7 17.01c.06.134-.035.287-.176.287m7.458 7.414L73.573.114A.195.195 0 0 0 73.398 0h-3.797a.197.197 0 0 0-.175.114L58.02 24.711c-.063.136.032.289.175.289h3.298a.288.288 0 0 0 .261-.172l2.022-4.43a.272.272 0 0 1 .24-.159h14.966c.102 0 .197.064.24.159l2.023 4.43a.29.29 0 0 0 .263.172h3.298c.143 0 .236-.153.175-.289"
            fill="rgb(255, 88, 67)"
          ></path>
        </g>
      </svg>
      {isServiceEnabled && (
        <div className="right-0 top-0 self-start">
          <span className=" font-bold text-white">Service</span>
        </div>
      )}
    </Button>
  );
}
