import { NotificationChannel } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { Heading } from "@radix-ui/themes";
import { getQueryKey } from "@trpc/react-query";
import { SaveIcon, UndoIcon } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { SettingRoutes } from "./setting-routes";
import UserNotFound from "./user-not-found";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { PasswordInput } from "../../components/ui/password";
import { Switch } from "../../components/ui/switch";
import { useToast } from "../../components/ui/use-toast";
import { queryClient, trpc } from "../../config/trpc";
import { useUser } from "../../containers/hooks";
import { strongPasswordSchema } from "../../utils/string";

const editUserForm = z.object({
  email: z.string().trim().email().optional(),
  firstname: z.string().trim().min(1),
  lastname: z.string().trim().min(1),
  notificationByEmailEnabled: z.boolean(),
  notificationBySmsEnabled: z.boolean(),
  notificationByTeamsEnabled: z.boolean(),
  password: z
    .discriminatedUnion("change", [
      z.object({
        change: z.literal(false),
      }),
      z.object({
        change: z.literal(true),
        confirm: strongPasswordSchema,
        currentPassword: z.string(),
        password: strongPasswordSchema,
      }),
    ])
    .refine((data) => !data.change || data.password === data.confirm, {
      message: "Passwords don't match",
      path: ["password"],
    }),
});

type EditUserForm = z.infer<typeof editUserForm>;

const getNotificationChannels = (data: EditUserForm): NotificationChannel[] => {
  const channels: NotificationChannel[] = [];
  if (data.notificationByEmailEnabled) {
    channels.push(NotificationChannel.EMAIL);
  }
  if (data.notificationBySmsEnabled) {
    channels.push(NotificationChannel.SMS);
  }
  if (data.notificationByTeamsEnabled) {
    channels.push(NotificationChannel.TEAMS);
  }
  return channels;
};

export default function UserEditProfile() {
  const user = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();
  const defaultValues = useMemo(
    () =>
      ({
        email: user?.email ?? "",
        firstname: user?.firstname ?? "",
        lastname: user?.lastname ?? "",
        notificationByEmailEnabled: user?.notificationChannels.includes(
          NotificationChannel.EMAIL,
        ),
        notificationBySmsEnabled: user?.notificationChannels.includes(
          NotificationChannel.SMS,
        ),
        notificationByTeamsEnabled: user?.notificationChannels.includes(
          NotificationChannel.TEAMS,
        ),
        password: {
          change: false,
        },
      }) as EditUserForm,
    [user],
  );

  const form = useForm<EditUserForm>({
    defaultValues,
    resolver: zodResolver(editUserForm),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const { mutate: updateUserDetails } =
    trpc.account.user.updateDetails.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error updating",
          variant: "destructive",
        });
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.account.user.myself),
        });
        navigate(SettingRoutes.PROFILE);
      },
    });

  if (!user) {
    return <UserNotFound />;
  }

  const onSubmit = (data: EditUserForm) => {
    updateUserDetails({
      firstname: data.firstname,
      lastname: data.lastname,
      notificationChannels: getNotificationChannels(data),
      oldPassword: data.password.change ? data.password.currentPassword : null,
      password: data.password.change ? data.password.password : null,
    });
  };

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-between">
          <Heading>Edit profile</Heading>
          <div className="space-x-2">
            <Button
              onClick={() => {
                navigate(SettingRoutes.PROFILE);
              }}
              variant={"secondary"}
            >
              <UndoIcon />
            </Button>
            <Button type="submit">
              <SaveIcon />
            </Button>
          </div>
        </div>
        <div className="bg-card space-y-2 rounded-lg border p-4">
          <FormField
            control={form.control}
            disabled={true}
            name={"email"}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl className="mt-0">
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={"firstname"}
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl className="mt-0">
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={"lastname"}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl className="mt-0">
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={"password"}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Edit password</FormLabel>
                <div>
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value.change}
                      onCheckedChange={(change) => {
                        field.onChange({ change });
                      }}
                    />
                  </FormControl>
                  {field.value.change && (
                    <div className="space-y-2">
                      <FormField
                        control={form.control}
                        name={"password.currentPassword"}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Current password</FormLabel>
                            <FormControl className="mt-0">
                              <PasswordInput type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name={"password.password"}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl className="mt-0">
                              <PasswordInput type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name={"password.confirm"}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Confirm password</FormLabel>
                            <FormControl className="mt-0">
                              <PasswordInput type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  )}
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <div>
            <FormLabel>Notification channels</FormLabel>
            <FormDescription>
              Please choose one or several way to be notified when some events
              happened
            </FormDescription>
            <div className="bg-card space-y-4 rounded-lg border p-4">
              <FormField
                control={form.control}
                name={"notificationByEmailEnabled"}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2">
                    <FormControl className="mt-0">
                      <Switch
                        checked={field.value}
                        className="mt-0"
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Email</FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"notificationBySmsEnabled"}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2">
                    <FormControl className="mt-0">
                      <Switch
                        checked={field.value}
                        className="mt-0"
                        disabled={true}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>SMS</FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"notificationByTeamsEnabled"}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2">
                    <FormControl className="mt-0">
                      <Switch
                        checked={field.value}
                        className="mt-0"
                        disabled={true}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Teams</FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
