import { PlateSize } from "@console/shared";
import { PlusCircle } from "lucide-react";
import { useState } from "react";

import { WorkflowActions, type WorkflowReducerActions } from "./reducer";
import { useWorkflowBuildContext } from "./workflow-build-context";

import { Button } from "../../../../../components/ui/button";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { useKitIdsToSizeMap } from "../../../../settings/organization-settings/hooks";
import PickKit from "../../assay/components/pick-kit";

export const AddPlate = ({
  dispatchWorkflowChange,
}: {
  dispatchWorkflowChange: React.Dispatch<WorkflowReducerActions>;
}) => {
  const { kitToAdd, setKitToAdd } = useWorkflowBuildContext();
  const [plateNameToAdd, setPlateNameToAdd] = useState("");

  const kitToSize = useKitIdsToSizeMap();
  const handleAddPlate = (kit: string) => {
    const size = kitToSize.get(kit) ?? PlateSize.S96;
    dispatchWorkflowChange({
      payload: {
        kit,
        name: plateNameToAdd,
        size,
      },
      type: WorkflowActions.ADD_PLATE,
    });
    setPlateNameToAdd("");
  };

  return (
    <div className="mt-2 flex items-end justify-end space-x-2">
      <div className="relative grow">
        <Input
          id="plateName"
          onChange={(e) => setPlateNameToAdd(e.target.value)}
          placeholder="Plate name required"
          required
          value={plateNameToAdd}
        />
      </div>
      <div className="flex flex-col">
        <Label htmlFor="kitSelection">Default Kit</Label>
        <PickKit onChange={setKitToAdd} value={kitToAdd} />
      </div>
      <Button
        className="flex flex-row items-center space-x-1"
        onClick={() => handleAddPlate(kitToAdd)}
        variant={"outline"}
      >
        <span className="w-[80px]">Add plate</span>
        <PlusCircle />
      </Button>
    </div>
  );
};
