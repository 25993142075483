import { ObjectMembership } from "@console/shared";
import { Archive, ArchiveRestore } from "lucide-react";

import { trpc } from "../../config/trpc";
import { capitalize } from "../../utils/string";
import { AlertDialogWrapper } from "../ui/alert-dialog";
import { useToast } from "../ui/use-toast";

const SubjectToLabel: Record<ObjectMembership, string> = {
  [ObjectMembership.Construct]: "construct",
  [ObjectMembership.Assay]: "assay",
  [ObjectMembership.Hamilton]: "hamilton instrument",
  [ObjectMembership.Syntax]: "instrument",
};

const useInvalidateQueriesAfterArchive = (subjectType: ObjectMembership) => {
  const utils = trpc.useUtils();

  return () => {
    if (subjectType === ObjectMembership.Assay) {
      utils.assay.list.invalidate();
    }
    if (subjectType === ObjectMembership.Construct) {
      utils.construct.list.invalidate();
    }
  };
};

export const ArchiveButton = ({
  id,
  subjectType,
}: {
  id: string;
  subjectType: ObjectMembership;
}) => {
  const { toast } = useToast();
  const subjectLabel = SubjectToLabel[subjectType];
  const invalidateQueries = useInvalidateQueriesAfterArchive(subjectType);

  const { mutate: archiveObject } =
    trpc.account.authorization.archive.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        invalidateQueries();
        toast({
          title: `${capitalize(subjectLabel)} archived`,
          variant: "default",
        });
      },
    });

  const handleArchiveObject = () => {
    archiveObject({
      id,
      subjectType,
    });
  };

  return (
    <AlertDialogWrapper
      description={`Are you sure you want to archive this ${subjectLabel} ?`}
      onConfirm={handleArchiveObject}
      title={`Archive ${subjectLabel}`}
    >
      <div className="flex flex-row items-center space-x-1">
        <Archive />
        <span>Archive</span>
      </div>
    </AlertDialogWrapper>
  );
};

export const RestoreButton = ({
  subjectType,
  id,
}: {
  id: string;
  subjectType: ObjectMembership;
}) => {
  const { toast } = useToast();
  const subjectLabel = SubjectToLabel[subjectType];
  const invalidateQueries = useInvalidateQueriesAfterArchive(subjectType);

  const { mutate: unarchiveObject } =
    trpc.account.authorization.unarchive.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        invalidateQueries();
        toast({
          title: `${capitalize(subjectLabel)} restored`,
          variant: "default",
        });
      },
    });

  const handleRestoreObject = () => {
    unarchiveObject({
      id,
      subjectType,
    });
  };

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={handleRestoreObject}
    >
      <ArchiveRestore />
      <span>Restore</span>
    </div>
  );
};
