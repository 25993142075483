import { PlateSize } from "@console/shared";
import { RotateCcw } from "lucide-react";
import { useDrag, useDrop } from "react-dnd";

import { WorkflowActions } from "./reducer";
import type { Well } from "./types";
import { DragObjects } from "./types";
import { getWellId } from "./utils";
import { useWorkflowBuildContext } from "./workflow-build-context";

import { cn } from "../../../../../lib/utils";

export const WellWorkflow = ({
  well,
  plateId,
  plateIndex,
}: {
  plateId: string;
  plateIndex: number;
  well: Well;
}) => {
  const { dispatchWorkflowChange, plateIdToPlate } = useWorkflowBuildContext();
  const { index, oligoId } = well;
  const hasOligo = Boolean(oligoId);
  const isPlateLocked = plateIdToPlate.get(plateId)?.locked ?? false;
  const isPlateHighPlex = plateIdToPlate.get(plateId)?.size === PlateSize.S384;

  const moveWell = (
    from: { plateId: string; wellIndex: string },
    to: { plateId: string; wellIndex: string },
  ) => {
    dispatchWorkflowChange({
      payload: {
        from,
        to,
      },
      type: WorkflowActions.MOVE_WELL,
    });
  };

  const assignOligosToWell = (oligoIds: string[], wellIndex: string) => {
    dispatchWorkflowChange({
      payload: {
        oligoIds,
        plateIndex,
        wellIndex,
      },
      type: WorkflowActions.ASSIGN_OLIGOS_TO_WELL,
    });
  };

  const resetWell = () => {
    dispatchWorkflowChange({
      payload: {
        plateIndex,
        wellIndex: index,
      },
      type: WorkflowActions.RESET_WELL,
    });
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DragObjects.Well, DragObjects.Oligo],
      canDrop: () => !isPlateLocked,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      drop: (
        item:
          | { id: string }
          | { ids: string[] }
          | {
              plateId: string;
              wellIndex: string;
            },
      ) => {
        if ("plateId" in item) {
          // Received well
          moveWell(item, { plateId, wellIndex: index });
          return;
        }
        // Received oligo
        assignOligosToWell("id" in item ? [item.id] : item.ids, index);
      },
    }),
    [],
  );

  const [collected, drag] = useDrag(
    () => ({
      canDrag: () => hasOligo && !isPlateLocked,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: () => {
        return {
          plateId,
          wellIndex: index,
        };
      },
      type: DragObjects.Well,
    }),
    [plateId, index, hasOligo],
  );

  const scrollToOligoId = () => {
    if (!oligoId) {
      return;
    }
    const element = document.getElementById(oligoId);
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.metaKey) {
      scrollToOligoId();
    }
  };

  const onMouseEnter = () => {
    document
      .querySelectorAll(`[data-group='${well.oligoId}']`)
      .forEach((el) => {
        el.classList.add("ring");
      });
  };

  const onMouseLeave = () => {
    document
      .querySelectorAll(`[data-group='${well.oligoId}']`)
      .forEach((el) => {
        el.classList.remove("ring");
      });
  };

  return (
    <div
      className={cn(
        "group relative flex aspect-square items-center justify-center  border bg-white hover:ring",
        isPlateHighPlex ? "rounded-sm" : "rounded-full",
        isOver && "ring",
        hasOligo && "bg-green-200",
        collected.isDragging && "bg-orange-200",
        hasOligo && "cursor-grab",
      )}
      data-group={well.oligoId}
      id={getWellId(plateId, index)}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={(node) => {
        drop(node);
        drag(node);
      }}
    >
      {!isPlateLocked && (
        <RotateCcw
          className={cn(
            "absolute right-0 top-0 cursor-pointer rounded-full opacity-0 hover:bg-white",
            hasOligo && "group-hover:opacity-100",
          )}
          onClick={resetWell}
          size={14}
        />
      )}
      <p>{index}</p>
    </div>
  );
};
