import { Download } from "lucide-react";

import { Button } from "../../../../../components/ui/button";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { downloadBase64Excel } from "../../../../../utils/useDownload";

export default function DownloadPlateLayout({ id }: { id: string }) {
  const { toast } = useToast();
  const { mutate: downloadLayout } =
    trpc.order.productionPlates.downloadLayout.useMutation({
      onSuccess(data) {
        downloadBase64Excel(data, "layout.xlsx");
        toast({
          description: "PLEASE DO NOT CHANGE THE LAYOUT ON THE PROTO.",
          title: "Layout downloaded",
          variant: "success",
        });
      },
    });

  return (
    <Button
      className="flex flex-row items-center space-x-1"
      onClick={() => downloadLayout(id)}
      variant={"outline"}
    >
      <Download />
      <span>Layout</span>
    </Button>
  );
}
