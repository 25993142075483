import type { PlateSize } from "@console/shared";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

import { DISPLAY_COLUMNS_AND_ROWS } from "./display";

import { cn } from "../../../../../../lib/utils";

export default function Column({
  isEditable,
  column,
  size,
  children,
  handleMoveColumn,
}: {
  children: React.ReactNode;
  column: string;
  handleMoveColumn: (originColumn: string, targetColumn: string) => void;
  isEditable: boolean;
  size: PlateSize;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, drag, preview] = useDrag(() => ({
    canDrag: isEditable,
    item: {
      column,
    },
    type: "Column",
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Column",
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      drop: (item) => {
        const originColumn = (item as { column: string }).column;
        handleMoveColumn(originColumn, column);
      },
    }),
    [handleMoveColumn],
  );

  return (
    <div
      className={cn(
        `grid-rows-${DISPLAY_COLUMNS_AND_ROWS[size].rows} grid auto-rows-fr gap-1 rounded-lg`,
        isOver ? "bg-slate-400" : "",
      )}
      ref={(node) => preview(drop(node))}
    >
      <div
        className="flex cursor-grab  items-center justify-center gap-1"
        ref={drag}
      >
        {column}
      </div>
      <React.Fragment>{children}</React.Fragment>
    </div>
  );
}
