import type { ColumnDef, Row, RowData } from "@tanstack/react-table";

import { IndeterminateCheckbox } from "../table/basic";
import { Checkbox } from "../ui/checkbox";
import { SELECTION_COLUMN_ID } from "../ui/data-table/data-table-pagination";

export function CellCheckbox<T extends RowData>({ row }: { row: Row<T> }) {
  // TODO: understand why Checkbox from radix UI doesn't work
  return (
    <IndeterminateCheckbox
      aria-label="Select row"
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={(ev) => {
        row.getToggleSelectedHandler()(ev);
      }}
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    />
  );
}

const HeaderCheckbox = ({ table }: { table: any }) => {
  return (
    <Checkbox
      aria-label="Select all"
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && "indeterminate")
      }
      className="border-secondary data-[state=checked]:bg-secondary"
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      type="button"
    />
  );
};

export function SelectColumn<T extends RowData>(
  SelectionComponent: React.ComponentType<{ row: Row<T> }> | undefined,
): ColumnDef<T> {
  return {
    cell: CellCheckbox,
    enableHiding: false,
    enableSorting: false,
    header: HeaderCheckbox,
    id: SELECTION_COLUMN_ID,
    meta: {
      SelectionComponent,
    },
  };
}
