import { Normalization } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { SaveIcon, UploadIcon } from "lucide-react";
import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import PickConcentration from "./components/pick-concentration";
import PickKit from "./components/pick-kit";
import { useGetCurrentKitConcentrations } from "./hooks/useGetCurrentKitConcentrations";

import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { LoadingSpinner } from "../../../../components/ui/spinner";
import { trpc } from "../../../../config/trpc";
import { cn } from "../../../../lib/utils";
import { useGetAvailableKits } from "../../../settings/organization-settings/hooks";
import { OrganizationRoutes } from "../../organization-routes";
import OligoSet from "../construct/components/oligo-set";
import { oligosSchemaWithValidation } from "../construct/edit-construct";

const quickPlateForm = z.object({
  kit: z.string(),
  name: z.string().trim().min(1),
  oligos: oligosSchemaWithValidation,
  quantification: z.object({
    defaultConcentration: z.number().optional(),
    defaultNormalization: z.nativeEnum(Normalization),
  }),
});

type QuickPlateForm = z.infer<typeof quickPlateForm>;

export const QuickPlateUploadButton = React.forwardRef(() => {
  const navigate = useNavigate();
  return (
    <Button
      className="space-x-1"
      onClick={() => navigate(OrganizationRoutes.QUICK_PLATE)}
      variant={"outline"}
    >
      <span>Quick plate</span>
      <UploadIcon />
    </Button>
  );
});
QuickPlateUploadButton.displayName = "QuickPlateUploadButton";

export default function QuickPlateUpload() {
  const navigate = useNavigate();

  const form = useForm<QuickPlateForm>({
    defaultValues: {
      kit: "",
      name: "",
      oligos: [],
      quantification: {
        defaultNormalization: Normalization.None,
      },
    },
    resolver: zodResolver(quickPlateForm),
  });
  const availableKits = useGetAvailableKits();
  const currentKit = form.watch("kit");
  const { kitConcentrationMax, kitConcentrationMin } =
    useGetCurrentKitConcentrations(currentKit);

  useEffect(() => {
    form.setValue("kit", availableKits[0]);
  }, [availableKits, form]);

  const { mutate: createPlate, isPending } =
    trpc.assay.createQuickPlate.useMutation({
      onSuccess(url) {
        navigate(url);
      },
    });

  const handleSavePlate = (data: QuickPlateForm) => {
    createPlate({
      concentration: data.quantification.defaultConcentration,
      kit: data.kit,
      name: data.name,
      normalization: data.quantification.defaultNormalization,
      oligos: data.oligos.map((o) => ({
        name: o.name,
        placement: o.wellHint ?? undefined,
        sequence: o.sequence,
      })),
    });
  };

  return (
    <Form {...form}>
      <form
        className={cn(
          "flex flex-col space-y-4",
          isPending ? "pointer-events-none opacity-70" : "",
        )}
        onSubmit={form.handleSubmit(handleSavePlate)}
      >
        <Card>
          <CardHeader>
            <CardTitle>Quick plate upload</CardTitle>
            <CardDescription>
              Quickly get to a plate ready to print
            </CardDescription>
            <div className="flex w-full flex-row justify-end">
              <Button
                className="flex max-w-[50%] flex-row items-center space-x-1"
                type="submit"
              >
                <span>Submit</span>
                <SaveIcon />
              </Button>
              {isPending && (
                <div className="flex flex-row justify-center">
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </CardHeader>
          <CardContent>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input className="w-1/2" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="oligos"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Oligos</FormLabel>
                  <FormControl>
                    <OligoSet
                      data={field.value}
                      setData={(oligos) => {
                        const newOligos =
                          typeof oligos === "function"
                            ? oligos(field.value)
                            : oligos;
                        field.onChange(newOligos);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="kit"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Kit</FormLabel>
                  <FormControl className="mt-0">
                    <PickKit
                      onChange={(value) => field.onChange(value)}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="quantification"
              render={({ field }) => (
                <FormItem className="flex flex-col space-x-2">
                  <div>
                    <FormLabel>Default Normalization</FormLabel>
                    <FormDescription>
                      Choose a default normalization and potentially
                      concentration to use for all plates. You can change it for
                      each well later on.
                    </FormDescription>
                  </div>
                  <FormControl className="mt-0">
                    <div className="flex flex-row pb-6">
                      <PickConcentration
                        currentConcentration={
                          field.value.defaultConcentration ??
                          kitConcentrationMin
                        }
                        handleApplyConcentrationChange={(
                          normalization,
                          concentration,
                        ) => {
                          const concentrationToSet =
                            normalization === Normalization.Target &&
                            !concentration
                              ? kitConcentrationMin
                              : concentration;

                          field.onChange({
                            defaultConcentration: concentrationToSet,
                            defaultNormalization: normalization,
                          });
                        }}
                        isEditable={true}
                        kitConcentrationMax={kitConcentrationMax}
                        kitConcentrationMin={kitConcentrationMin}
                        normalization={field.value.defaultNormalization}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </Card>
      </form>
    </Form>
  );
}
