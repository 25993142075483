import { SequenceShippingFormat } from "@console/shared";
import { Grid, TestTube } from "lucide-react";

export const ShippingFormatComponent: Record<
  SequenceShippingFormat,
  React.ReactElement
> = {
  [SequenceShippingFormat.Plate]: (
    <p className="flex flex-row items-center space-x-1">
      <span>Plate</span>
      <Grid />
    </p>
  ),
  [SequenceShippingFormat.Tube]: (
    <p className="flex flex-row items-center space-x-1">
      <span>Tube</span>
      <TestTube />
    </p>
  ),
};
