import type { Role } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import { useParams } from "react-router-dom";

import { RolesBlock } from "./components/roles-block";
import TeamMembershipsBlock from "./components/team-memberships-block";

import { IsActive } from "../../../components/table/basic";
import CopyToClipboard from "../../../components/ui/clipboard";
import { trpc } from "../../../config/trpc";
import { CheckIfTrueElseCross } from "../../admin/organizations/components/ui";

export default function OrganizationMember() {
  const { userId } = useParams();
  const { data: member } = trpc.account.organizationUser.get.useQuery(
    userId ?? "",
    {
      enabled: !!userId,
    },
  );

  const utils = trpc.useUtils();
  const { mutate: updateRoles } =
    trpc.account.organizationUser.updateRoles.useMutation({
      onSuccess() {
        utils.account.organizationUser.get.invalidate(userId ?? "");
      },
    });
  const handleUpdatePermissions = (roles: Role[]) => {
    if (!userId) {
      return;
    }
    updateRoles({
      id: userId,
      roles,
    });
  };

  if (!member) {
    return null;
  }

  const { user } = member;
  const { email, name, mfaActivated } = user;

  return (
    <div className="space-y-8">
      <Heading>{`User ${member.user.name}`}</Heading>
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <div className="flex flex-row items-center justify-between space-x-2">
          <h3 className="font-bold">Details</h3>
          <IsActive isActive={member.status === "ACTIVE"} />
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span>Email</span>
          <span>{email}</span>
          <CopyToClipboard value={email} />
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span>Name</span>
          <span>{name}</span>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span>MFA</span>
          <CheckIfTrueElseCross value={mfaActivated} />
          <span>{mfaActivated ? "Activated" : "Not activated"}</span>
        </div>
      </div>
      <RolesBlock
        editable={false}
        roles={member.user.roles}
        title="Global roles"
      />
      <RolesBlock
        editable={true}
        handleChangeAuthorizations={handleUpdatePermissions}
        roles={member.roles}
      />
      {userId && <TeamMembershipsBlock organizationUserId={userId} />}
    </div>
  );
}
