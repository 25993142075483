import { z } from "zod";
import { AdditionalService, SequenceShippingFormat } from "./order";

export const sequenceColumnId = "sequence";
const aminoAcids = "ACDEFGHIKLMNPQRSTVWY";
const aminoAcidsWithoutBases = "EILPQ";
const degenerateBases = "BDHKMNRSVWY";
const bases = "ATCG";

const biologyBases = new RegExp(`^[${bases}${aminoAcids}${degenerateBases}]+$`);
const notAminoAcids = new RegExp(`[^${aminoAcidsWithoutBases}]`);
const notDegenerateBases = new RegExp(`[^${degenerateBases}]`);
export const minGeneLength = 150;
export const minOligoLength = 15;
export const maxGeneLength = 5000;
export const maxOligoLength = 500;
export const maxOligos = 384;
export const maxGenes = 96;
const minimumLongGenesInCategory = 24;
export const minimumOligosPerPlate = 24;

function isSequenceBetweenLimits(sequence: string, min: number, max: number) {
  return sequence.length >= min && sequence.length <= max;
}

function isSequenceLong(sequence: string) {
  return isSequenceBetweenLimits(sequence, 1000, 3000);
}
function isSequenceVeryLong(sequence: string) {
  return isSequenceBetweenLimits(sequence, 3000, 5000);
}

const sequenceSchema = z
  .string()
  .min(1, {
    message: "Sequence must be at least 1 character long",
  })
  .toUpperCase()
  .regex(biologyBases, {
    message: "Sequence can only contain nucleotides",
  })
  .regex(notAminoAcids, {
    message: "Sequence cannot contain amino acids",
  });

const oligoSequenceSchema = sequenceSchema
  .min(minOligoLength, `Oligo length must be at least ${minOligoLength} bp`)
  .max(
    maxOligoLength,
    `Oligo length must not exceed than ${maxOligoLength} bp`,
  );

const geneFragmentSchema = sequenceSchema
  .min(minGeneLength, `Gene length must be at least ${minGeneLength} bp`)
  .max(maxGeneLength, `Gene length must not exceed than ${maxGeneLength} bp`)
  .regex(notDegenerateBases, {
    message: "Gene sequence cannot contain degenerate bases",
  });

const getSequenceItemSchema = (isOligo: boolean) =>
  z.object({
    additionalServices: z.array(z.nativeEnum(AdditionalService)).optional(),
    format: z
      .nativeEnum(SequenceShippingFormat)
      .default(SequenceShippingFormat.Plate),
    name: z.string(),
    sequence: isOligo ? oligoSequenceSchema : geneFragmentSchema,
  });

const oligosSchema = getSequenceItemSchema(true);

export type Sequence = z.infer<typeof oligosSchema>;

export const requestFormSchema = z
  .object({
    geneFragments: z.array(getSequenceItemSchema(false)).max(maxGenes),
    oligos: z.array(getSequenceItemSchema(true)).max(maxOligos),
  })
  .refine(
    (data) => {
      return data.oligos.length > 0 || data.geneFragments.length > 0;
    },
    {
      message: "You must have at least one oligo or gene fragment",
    },
  )
  .superRefine((data, ctx) => {
    const mapOfOligoNames = new Map<
      string,
      { index: number; isOligo: boolean }[]
    >();
    data.oligos.forEach((oligo, index) => {
      if (mapOfOligoNames.has(oligo.name)) {
        mapOfOligoNames.get(oligo.name)!.push({ index, isOligo: true });
      } else {
        mapOfOligoNames.set(oligo.name, [{ index, isOligo: true }]);
      }
    });
    data.geneFragments.forEach((gene, index) => {
      if (mapOfOligoNames.has(gene.name)) {
        mapOfOligoNames.get(gene.name)!.push({ index, isOligo: false });
      } else {
        mapOfOligoNames.set(gene.name, [{ index, isOligo: false }]);
      }
    });
    Array.from(mapOfOligoNames.entries()).forEach(([name, indexes]) => {
      if (indexes.length <= 1) {
        return;
      }
      indexes.forEach(({ index, isOligo }) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Name ${name} must be unique`,
          path: [isOligo ? "oligos" : "geneFragments", index, "name"],
        });
      });
    });

    const oligoIndicesInPlate = data.oligos
      .map((o, i) => ({
        i,
        o,
      }))
      .filter((o) => o.o.format === SequenceShippingFormat.Plate)
      .map((o) => o.i);
    if (oligoIndicesInPlate.length < minimumOligosPerPlate) {
      oligoIndicesInPlate.forEach((index) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You must have at least ${minimumOligosPerPlate} oligos if you request a plate`,
          path: ["oligos", index, "sequence"],
        });
      });
    }

    const countOfLongGenesInPlates = data.geneFragments.reduce(
      (acc, gene) =>
        acc +
        (isSequenceLong(gene.sequence) &&
        gene.format === SequenceShippingFormat.Plate
          ? 1
          : 0),
      0,
    );
    const countOfVeryLongGenesInPlates = data.geneFragments.reduce(
      (acc, gene) =>
        acc +
        (isSequenceVeryLong(gene.sequence) &&
        gene.format === SequenceShippingFormat.Plate
          ? 1
          : 0),
      0,
    );
    data.geneFragments.forEach((gene, index) => {
      if (
        isSequenceLong(gene.sequence) &&
        countOfLongGenesInPlates < minimumLongGenesInCategory &&
        gene.format === SequenceShippingFormat.Plate
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You must have at least ${minimumLongGenesInCategory} genes between 1000-3000 bp`,
          path: ["geneFragments", index, "sequence"],
        });
      }
      if (
        isSequenceVeryLong(gene.sequence) &&
        countOfVeryLongGenesInPlates < minimumLongGenesInCategory &&
        gene.format === SequenceShippingFormat.Plate
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You must have at least ${minimumLongGenesInCategory} genes between 3000-5000 bp`,
          path: ["geneFragments", index, "sequence"],
        });
      }
    });
  });

export type RequestFormType = z.infer<typeof requestFormSchema>;
