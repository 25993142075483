import { PlateSize, Normalization, Purity } from "@console/shared";
import { useMemo } from "react";

import type { WorkflowWell } from "./plate/types";

export const PLATE_SIZES: Record<
  PlateSize,
  { columns: number; rows: number; total: number }
> = {
  [PlateSize.S96]: {
    columns: 12,
    rows: 8,
    total: 96,
  },
  [PlateSize.S384]: {
    columns: 24,
    rows: 16,
    total: 384,
  },
};

export const getColumns = (size: PlateSize) =>
  Array(PLATE_SIZES[size].columns)
    .fill(0)
    .map((_, i) => (i + 1).toString());

export const getRows = (size: PlateSize) =>
  Array(PLATE_SIZES[size].rows)
    .fill(0)
    .map((_, i) => String.fromCharCode(65 + i));

const useGetDBWellByIndex = (wells: WorkflowWell[]) => {
  return useMemo(() => {
    const wellSet = new Map<string, WorkflowWell>();
    wells.forEach((well) => wellSet.set(well.index, well));
    return wellSet;
  }, [wells]);
};

export const useGetAllWellsById = (size: PlateSize, wells: WorkflowWell[]) => {
  const columns = useMemo(() => getColumns(size), [size]);
  const rows = useMemo(() => getRows(size), [size]);
  const filledWellsByWellId = useGetDBWellByIndex(wells);

  return useMemo(() => {
    const wellSet = new Map<string, WorkflowWell>();
    rows.forEach((row) => {
      return columns.forEach((column) => {
        const wellIndex = `${row}${column}`;
        const well = filledWellsByWellId.get(wellIndex) ?? {
          __typename: "Well",
          column,
          errors: [],
          expectedConcentration: undefined,
          id: "",
          index: wellIndex,
          isLocal: true,
          name: "",
          normalization: Normalization.None,
          purityFlag: Purity.Unknown,
          row,
          sequence: "",
        };
        wellSet.set(well.index, well);
      });
    });
    return wellSet;
  }, [columns, filledWellsByWellId, rows]);
};
