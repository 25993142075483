import { PlateSize } from "@console/shared";

import {
  ConcentrationLegend,
  MeasuredConcentrationLegend,
  ModificationLegend,
  PurityLegend,
  SequenceLegend,
  VolumeLegend,
  YieldLegend,
} from "./legends";
import type { PlateKit } from "./types";

export const DISPLAYS = [
  "length",
  "concentration",
  "purity",
  "yield",
  "measuredConcentration",
  "volume",
  "modification",
] as const;
export type Display = (typeof DISPLAYS)[number];
export const DISPLAY_PROPERTIES: Record<
  Display,
  {
    Legend: React.FC<{ kitProperties: PlateKit }>;
    isResult: boolean;
    label: string;
    unit?: string;
  }
> = {
  concentration: {
    Legend: ConcentrationLegend,
    isResult: false,
    label: "Concentration",
    unit: "μM",
  },
  length: {
    Legend: SequenceLegend,
    isResult: false,
    label: "Length",
  },
  measuredConcentration: {
    Legend: MeasuredConcentrationLegend,
    isResult: true,
    label: "Concentration (m)",
    unit: "μM",
  },
  modification: {
    Legend: ModificationLegend,
    isResult: true,
    label: "Modifications",
  },
  purity: {
    Legend: PurityLegend,
    isResult: false,
    label: "Score",
  },
  volume: {
    Legend: VolumeLegend,
    isResult: true,
    label: "Volume",
    unit: "μL",
  },
  yield: { Legend: YieldLegend, isResult: true, label: "Yield", unit: "pmol" },
};

export const DISPLAY_COLUMNS_AND_ROWS: Record<
  PlateSize,
  { columns: number; rows: number }
> = {
  [PlateSize.S384]: { columns: 25, rows: 17 },
  [PlateSize.S96]: { columns: 13, rows: 9 },
};
