import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useGetKitLabels } from "../../../../admin/organizations/organization/components/useGetEnvironmentKits";
import { useGetAvailableKits } from "../../../../settings/organization-settings/hooks";

export default function PickKit({
  value,
  onChange,
}: {
  onChange: (value: string) => void;
  value: string;
}) {
  const kitLabeler = useGetKitLabels();
  const availableKits = useGetAvailableKits();

  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger className="text-nowrap" id="kitSelection">
        <SelectValue placeholder={"Pick kit"} />
      </SelectTrigger>
      <SelectContent>
        {availableKits.map((kit) => (
          <SelectItem className="overflow-ellipsis" key={kit} value={kit}>
            {kitLabeler(kit)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
