import { Normalization } from "@console/shared";
import type { inferProcedureInput } from "@trpc/server";
import { useParams } from "react-router-dom";

import type { WorkflowWell } from "./plate/types";

import { useToast } from "../../../../../components/ui/use-toast";
import { trpc, type AppRouter } from "../../../../../config/trpc";

type WellInput = inferProcedureInput<
  AppRouter["assay"]["steps"]["plate"]["updateWells"]
>["wells"][0];

const getConcentrationFromNormalization = (
  normalization: Normalization,
  concentration: number | undefined | null,
): number => {
  if (normalization === Normalization.None) {
    return 0;
  }
  if (normalization === Normalization.Lowest) {
    return -1;
  }
  return concentration ?? 0;
};

export function useSaveWellUpdates() {
  const { stepId } = useParams();
  const { toast } = useToast();
  const { mutate: updateWells, isPending } =
    trpc.assay.steps.plate.updateWells.useMutation({
      onError(error) {
        const zodError = error.data?.zodError;
        toast({
          description: zodError ? zodError : error.message,
          title: "Error updating wells",
          variant: "destructive",
        });
      },
    });

  const handleSaveWells = (wells: WorkflowWell[]) => {
    if (!stepId) {
      return;
    }
    const wellsInput: Record<string, WellInput> = Object.fromEntries(
      wells.map((w) => [
        [`${w.row}${w.column}`],
        {
          column: w.column,
          concentration: getConcentrationFromNormalization(
            w.normalization,
            w.expectedConcentration,
          ),
          id: w.id,
          name: w.name,
          row: w.row,
          sequence: w.sequence,
        },
      ]),
    );
    updateWells({
      stepId: stepId,
      wells: wellsInput,
    });
  };

  return { handleSaveWells, isPending };
}
