import Dagre from "@dagrejs/dagre";
import type { Edge, Node } from "reactflow";
import { MarkerType } from "reactflow";

import type { NodeData } from "./types";

import type { WorkflowTRPC } from "../../../../../../config/trpc";
import { getPercentage } from "../../../../../../utils/number";

export const NODE_EDGE_SIZE = 300;

function getNodeAndEdgesFromData(data: NodeData[]): {
  edges: Edge[];
  nodes: Node<NodeData>[];
} {
  const GRAPH = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  GRAPH.setGraph({ nodesep: 30, rankdir: "LR", ranksep: 50 });
  const edges: Edge[] = [];

  const nodes: (Node<NodeData> & { height: number; width: number })[] =
    data.map((d, index) => {
      const { id, needs } = d;
      needs.forEach((n) => {
        edges.push({
          id: `${id}-${n}`,
          markerEnd: {
            type: MarkerType.Arrow,
          },
          source: n,
          sourceHandle: "left",
          target: id,
          targetHandle: "right",
        });
      });
      return {
        data: d,
        draggable: false,
        height: NODE_EDGE_SIZE,
        id: d.id,
        position: {
          x: index * (NODE_EDGE_SIZE + 30),
          y: index * (NODE_EDGE_SIZE + 30),
        },
        type: "workflowStepNode",
        width: NODE_EDGE_SIZE,
      };
    });

  edges.forEach((edge) => GRAPH.setEdge(edge.source, edge.target));
  nodes.forEach((node) => GRAPH.setNode(node.id, node));

  Dagre.layout(GRAPH);
  const nodesWithPlacement = nodes.map((node) => {
    const { x, y } = GRAPH.node(node.id);
    return { ...node, position: { x, y } };
  });

  return { edges, nodes: nodesWithPlacement };
}

function computeGlobalProgress(
  phases: NonNullable<
    NonNullable<
      NonNullable<NonNullable<WorkflowTRPC>["steps"][0]["plate"]>["run"]
    >["progress"]
  >["phases"],
): number {
  const phasesWithTotalTime = phases.map((phase) => {
    const alreadySpentTime = Math.max(
      0,
      (new Date().getTime() - new Date(phase.startTime).getTime()) / 1000,
    );
    const totalTime = phase.estimatedRemainingTime.seconds + alreadySpentTime;
    return {
      ...phase,
      totalTime,
    };
  });
  const totalTime = phasesWithTotalTime.reduce(
    (acc, p) => acc + p.totalTime,
    0,
  );
  const totalRemaining = phasesWithTotalTime.reduce(
    (acc, p) => acc + p.estimatedRemainingTime.seconds,
    0,
  );
  if (totalRemaining > totalTime) {
    return 0;
  }
  return getPercentage(totalTime, totalTime - totalRemaining);
}

function getDataFromWorkflow(workflow: WorkflowTRPC): NodeData[] {
  const steps = workflow?.steps ?? [];
  if (!workflow) {
    return [];
  }
  const data: NodeData[] = [];

  steps.forEach((step) => {
    const { id, needs, neededBy, stepType } = step;
    const plate = "plate" in step ? step.plate : undefined;
    const successRate =
      plate && "run" in plate
        ? plate?.run?.report?.metrics?.successRate
        : undefined;
    const completion =
      plate && "run" in plate
        ? plate?.run?.report?.metrics
          ? 100
          : computeGlobalProgress(
              (plate.run?.progress?.phases ?? []).map((phase) => ({
                description: phase.description,
                estimatedRemainingTime: phase.estimatedRemainingTime,
                label: phase.label,
                startTime: phase.startTime,
              })),
            )
        : 0;
    data.push({
      assayId: workflow.assayId,
      completion,
      id,
      name: step.name,
      neededBy: neededBy,
      needs: needs,
      plate: plate
        ? {
            cycles: plate?.cycles,
            filledWells: plate?.filledWells,
            hasErrors: plate?.hasErrors,
            kit: plate?.kit,
            size: plate?.size,
            status: ("run" in plate && plate.run?.state) || null,
            successRate,
          }
        : undefined,
      type: stepType,
    });
  });
  return data;
}

export const getWorkflowGraph = (workflow: WorkflowTRPC) => {
  const data = getDataFromWorkflow(workflow);
  return getNodeAndEdgesFromData(data);
};
