import { InstrumentHardwareVersion } from "@console/shared";
import type { inferProcedureOutput } from "@trpc/server";
import { useParams } from "react-router-dom";

import type { AppRouter } from "../../config/trpc";
import { trpc } from "../../config/trpc";

export type InstrumentTRPC = inferProcedureOutput<
  AppRouter["instrument"]["syntax"]["get"]
>;
export type InstrumentInListTRPC = inferProcedureOutput<
  AppRouter["instrument"]["syntax"]["list"]
>[number];

export const instrumentHardwareVersions: {
  [key in InstrumentHardwareVersion]: string;
} = {
  [InstrumentHardwareVersion.HW_201]: "STX-200 (5.7+)",
  [InstrumentHardwareVersion.HW_301]: "STX-300 - Long Oligo Service (6.0+)",
};

export const useGetInstrument = () => {
  const { instrumentId } = useParams();
  const utils = trpc.useUtils();

  trpc.instrument.syntax.subscribeToChanges.useSubscription(
    { instrumentId: instrumentId ?? "" },
    {
      enabled: !!instrumentId,
      onData() {
        utils.instrument.syntax.get.invalidate({
          instrumentId: instrumentId ?? "",
        });
      },
    },
  );

  return trpc.instrument.syntax.get.useQuery(
    { instrumentId: instrumentId ?? "" },
    {
      enabled: !!instrumentId,
    },
  );
};

export const useGetHamiltonInstrument = () => {
  const { instrumentId } = useParams();

  return trpc.instrument.hamilton.get.useQuery(
    { instrumentId: instrumentId ?? "" },
    {
      enabled: !!instrumentId,
    },
  );
};
