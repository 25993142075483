import { Upload } from "lucide-react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { fromZodError } from "zod-validation-error";

import { Button } from "../../../../../components/ui/button";
import { useToast } from "../../../../../components/ui/use-toast";
import type { SequenceFileRow } from "../../../../../utils/parser";
import { parseSequencesFile } from "../../../../../utils/parser";

export default function UploadSequences({
  isLocked = false,
  onChange,
}: {
  isLocked?: boolean;
  onChange: (parsedFile: SequenceFileRow[]) => void;
}) {
  const { toast } = useToast();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const parseFiles = async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
          try {
            const parsedFile = await parseSequencesFile(file);
            if (!parsedFile.success || parsedFile.data.length === 0) {
              toast({
                description: parsedFile.data
                  ? "Could not read any rows"
                  : fromZodError(parsedFile.error).message,
                title: "Error parsing file",
                variant: "destructive",
              });
            } else {
              onChange(
                parsedFile.data.filter((row) => row.Name && row.Sequence),
              );
            }
          } catch {
            toast({
              description: "Error parsing file",
              title: "Error",
              variant: "destructive",
            });
          }
        }
      };
      parseFiles(acceptedFiles);
    },
    [onChange, toast],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "text/x-fasta": [".fasta"],
    },
    noDrag: false,
    onDrop,
    useFsAccessApi: false,
  });

  return (
    <div {...getRootProps()}>
      <input
        disabled={isLocked}
        {...getInputProps()}
        data-testid={"drop-oligos"}
      />
      <Button className="space-x-2" disabled={isLocked} variant={"secondary"}>
        <span>Upload input file(s)</span>
        <Upload />
      </Button>
    </div>
  );
}
