import { geneSequenceSchema } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Editor } from "./edit-gene/edit-gene";
import NewDesign from "./new-design";
import OldDesigns from "./old-designs";

import { Button } from "../../../../../components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Card,
} from "../../../../../components/ui/card";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { Switch } from "../../../../../components/ui/switch";
import { TextareaAutoGrow } from "../../../../../components/ui/textarea";
import { trpc } from "../../../../../config/trpc";
import type { ConstructFromTRPC } from "../constructTypes";
import { MIN_GENE_LENGTH } from "../schemas";

type GeneFromTRPC = NonNullable<ConstructFromTRPC["gene"]>;

const geneFormSchema = z
  .object({
    sequence: z.string(),
    vector: z
      .object({
        insertPosition: z.number().min(0),
        sequence: z.string(),
      })
      .optional(),
  })
  .refine(
    (data) => {
      if (!data.vector) {
        return true;
      }
      const { insertPosition, sequence } = data.vector;
      return insertPosition < sequence.length;
    },
    {
      message:
        "Insert position of the gene must be within the length of the vector sequence",
      path: ["vector", "insertPosition"],
    },
  )
  .refine(
    (data) => {
      const { sequence } = data;
      const parsedState = geneSequenceSchema.parse(JSON.parse(sequence));
      const sequenceLength =
        parsedState.root.children[0].children[0].text.length;
      return sequenceLength >= MIN_GENE_LENGTH;
    },
    {
      message: `Gene sequence length must be greater than or equal to ${MIN_GENE_LENGTH}`,
      path: ["sequence"],
    },
  );

type GeneForm = z.infer<typeof geneFormSchema>;

export default function Gene({
  gene,
  construct,
}: {
  construct: ConstructFromTRPC;
  gene: GeneFromTRPC;
}) {
  const form = useForm<GeneForm>({
    defaultValues: {
      sequence: gene.sequence,
      vector: gene.vector
        ? {
            insertPosition: gene.vector.insertPosition,
            sequence: gene.vector.sequence,
          }
        : undefined,
    },
    resolver: zodResolver(geneFormSchema),
  });

  const values = form.watch();
  const useVector = values.vector !== undefined;
  const isGeneLocked = gene.locked;

  const utils = trpc.useUtils();
  const { mutate: updateGene } = trpc.construct.updateGene.useMutation({
    onSuccess() {
      utils.construct.get.invalidate({ id: construct.id });
    },
  });

  const handleUpdateConstruct = (data: GeneForm) => {
    updateGene({
      constructId: construct.id,
      gene: {
        sequence: data.sequence,
        vector: data.vector,
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Gene</CardTitle>
        <CardDescription>Gene details</CardDescription>
      </CardHeader>
      <Form {...form}>
        <form
          className="space-y-4"
          onSubmit={form.handleSubmit(handleUpdateConstruct)}
        >
          <CardContent className="flex flex-col space-y-4">
            <div className="flex items-center justify-end space-x-2">
              <Button type="submit">Submit</Button>
              <Button
                onClick={() => {
                  form.reset();
                }}
                type="button"
                variant={"secondary"}
              >
                Cancel
              </Button>
            </div>
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="sequence"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Gene sequence</FormLabel>
                    <FormControl>
                      <Editor
                        initialValue={gene.sequence}
                        setValue={(newValue) => {
                          if (isGeneLocked) {
                            return;
                          }
                          field.onChange(newValue);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-row items-center space-x-2">
                <Switch
                  checked={form.watch("vector") !== undefined}
                  disabled={isGeneLocked}
                  id="vectorSwitch"
                  onCheckedChange={(checked) => {
                    form.setValue(
                      "vector",
                      checked
                        ? {
                            insertPosition: 0,
                            sequence: "",
                          }
                        : undefined,
                    );
                  }}
                />
                <Label htmlFor="vectorSwitch">Use backbone vector</Label>
              </div>
              {useVector && (
                <div className="ml-10">
                  <FormField
                    control={form.control}
                    name="vector.sequence"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Vector sequence</FormLabel>
                        <FormControl>
                          <div className="relative space-x-2">
                            <TextareaAutoGrow
                              className="pr-10"
                              disabled={isGeneLocked}
                              id="geneSequence"
                              onChange={(e) => field.onChange(e)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  e.currentTarget.blur();
                                }
                              }}
                              value={field.value}
                            />
                            <span className="absolute right-2 top-0">
                              {field.value.length}
                            </span>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="vector.insertPosition"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Gene insert position on vector</FormLabel>
                        <FormControl>
                          <div className="relative space-x-2">
                            <Input
                              className="h-100 pr-10"
                              disabled={isGeneLocked}
                              min={0}
                              onChange={(e) =>
                                field.onChange(Number(e.target.value))
                              }
                              type="number"
                              value={field.value}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
            </div>
          </CardContent>
        </form>
      </Form>
      <CardHeader>
        <CardTitle>Designs</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex justify-end">
          <NewDesign
            assayId={construct.assay?.id}
            constructId={construct.id}
            constructName={construct.name}
            geneId={gene?.id}
          />
        </div>
        <OldDesigns constructId={construct.id} geneId={gene.id} />
      </CardContent>
    </Card>
  );
}
