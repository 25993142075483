import { PlateSize } from "@console/shared";
import { useEffect } from "react";

import NewPlateLegend from "./new-plate-legend";
import NewPlateWell from "./new-plate-well";
import type {
  NewPlateProperties,
  NewPlateReducerActions,
} from "./new-production-plate.reducer";
import { NewPlateAction } from "./new-production-plate.reducer";

import GenericPlate from "../../../../../components/plate/generic-plate";
import { Button } from "../../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../components/ui/card";
import CustomLink from "../../../../../components/ui/custom-link";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";
import PickKit from "../../assay/components/pick-kit";

export default function NewPlateMaker({
  dispatcher,
  newPlate,
  cancel,
}: {
  cancel: () => void;
  dispatcher: React.Dispatch<NewPlateReducerActions>;
  newPlate: NewPlateProperties;
}) {
  const { toast } = useToast();
  const size = PlateSize.S96;

  const { mutate: generatePlateName } =
    trpc.order.productionPlates.yieldName.useMutation({
      onSuccess(name) {
        dispatcher({ payload: name, type: NewPlateAction.ChangeName });
      },
    });

  useEffect(() => {
    generatePlateName();
  }, [generatePlateName]);

  const reset = () => dispatcher({ type: NewPlateAction.Init });

  const utils = trpc.useUtils();
  const { mutate: createPlate } =
    trpc.order.productionPlates.create.useMutation({
      onError(err) {
        const zodError = err.data?.zodError;
        const description = zodError ? zodError : err.message;
        toast({
          description,
          title: "Error creating plate",
          variant: "destructive",
        });
      },
      onSuccess(data) {
        utils.order.oligos.invalidate();
        toast({
          description: (
            <div>
              <p>Plate created successfully</p>
              <CustomLink
                to={OrganizationRoutes.SERVICE_PLATE.replace(
                  ":plateId",
                  data.id,
                )}
              >
                View plate
              </CustomLink>
            </div>
          ),
          title: "Plate created",
          variant: "success",
        });
      },
    });

  const handleCreatePlate = () => {
    if (!newPlate.name) {
      toast({
        description: "Please provide a name for the plate",
        title: "Invalid plate name",
        variant: "destructive",
      });
      return;
    }
    createPlate({
      kit: newPlate.kit,
      name: newPlate.name,
      wells: newPlate.wells.map((w) => ({
        index: w.wellIndex,
        oligoId: w.oligoId,
      })),
    });
    reset();
  };

  return (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle>New plate</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col space-y-2 p-4">
        <NewPlateLegend />
        <GenericPlate
          size={size}
          wellRenderer={(index) => {
            const wellWithOligo = newPlate.wells.find(
              (w) => w.wellIndex === index,
            );
            return <NewPlateWell index={index} well={wellWithOligo} />;
          }}
        />
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-end space-x-2">
            <div className="flex flex-col space-y-1">
              <Label htmlFor="plateName">Name</Label>
              <Input
                className="min-w-[180px]"
                id="plateName"
                onChange={(e) =>
                  dispatcher({
                    payload: e.target.value,
                    type: NewPlateAction.ChangeName,
                  })
                }
                value={newPlate.name}
              />
            </div>
            <div className="flex flex-col">
              <Label htmlFor="kitSelection">Kit</Label>
              <PickKit
                onChange={(newKit) => {
                  dispatcher({
                    payload: newKit,
                    type: NewPlateAction.ChangeKit,
                  });
                }}
                value={newPlate.kit}
              />
            </div>
            <Button onClick={reset} variant={"outline"}>
              Reset assignment
            </Button>
          </div>
          <div className="flex justify-end space-x-2">
            <Button onClick={cancel} variant={"outline"}>
              Cancel
            </Button>
            <Button onClick={handleCreatePlate} type="button">
              Create plate
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
