import type { ObjectMembership } from "@console/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { UserPlus } from "lucide-react";
import { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { z } from "zod";

import { SelectColumn } from "./select-column";

import { trpc, type AppRouter } from "../../config/trpc";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { DataTable } from "../ui/data-table/data-table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Form } from "../ui/form";
import { Separator } from "../ui/separator";
import { useToast } from "../ui/use-toast";

type Member = inferProcedureOutput<
  AppRouter["account"]["organizationUser"]["listWithNoAccessToObject"]
>[0];
type Team = inferProcedureOutput<
  AppRouter["account"]["authorization"]["teamsWithoutAccessToObject"]
>[0];

const memberColumns: ColumnDef<Member>[] = [
  SelectColumn(({ row }) => (
    <Badge variant={"secondary"}>{row.original?.user?.name}</Badge>
  )),
  {
    accessorKey: "user.email",
    cell: (info) => info.getValue(),
    header: "Email",
    id: "email",
  },
  {
    accessorKey: "user.name",
    cell: (info) => info.getValue(),
    header: "Name",
    id: "name",
  },
];

const teamColumns: ColumnDef<Team>[] = [
  SelectColumn(({ row }) => (
    <Badge variant={"secondary"}>{row.original?.name}</Badge>
  )),
  {
    accessorKey: "name",
    cell: (info) => info.getValue(),
    header: "Name",
    id: "name",
  },
];

const addOwnersForm = z.object({
  teams: z.array(z.object({ id: z.string() })),
  users: z.array(z.object({ id: z.string() })),
});

export type AddOwners = z.infer<typeof addOwnersForm>;

export default function AddOwner({
  objectName,
  objectId,
  subject,
}: {
  objectId: string;
  objectName: string;
  subject: ObjectMembership;
}) {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);

  const { data: membersData } =
    trpc.account.organizationUser.listWithNoAccessToObject.useQuery({
      id: objectId,
      subject,
    });

  const { data: teamsWithoutAccessTo } =
    trpc.account.authorization.teamsWithoutAccessToObject.useQuery(
      {
        id: objectId,
        subject,
      },
      {
        initialData: [],
      },
    );

  const membersWithoutAccessTo = membersData ?? [];

  const utils = trpc.useUtils();

  const { mutate: addOwners } =
    trpc.account.authorization.addOwners.useMutation({
      onError(err) {
        toast({
          description: err.message,
          title: "Error",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess() {
        utils.account.organizationUser.listWithNoAccessToObject.invalidate();
        utils.account.authorization.teamsWithoutAccessToObject.invalidate();
        utils.account.authorization.owners.invalidate({
          id: objectId,
          subjectType: subject,
        });

        setOpen(false);
      },
    });

  function onSubmit(values: AddOwners) {
    addOwners({
      id: objectId,
      subjectType: subject,
      teams: values.teams.map((t) => t.id),
      users: values.users.map((m) => m.id),
    });
  }

  const form = useForm<AddOwners>({
    defaultValues: {
      teams: [],
      users: [],
    },
    resolver: zodResolver(addOwnersForm),
  });

  const { fields: usersFields } = useFieldArray({
    control: form.control,
    name: "users",
  });
  const { fields: teamsFields } = useFieldArray({
    control: form.control,
    name: "teams",
  });
  const container = document.getElementById("detail");

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          aria-label="add-user-to-owners"
          className="flex items-center justify-center space-x-1"
          variant={"outline"}
        >
          <span>Add owner</span>
          <UserPlus />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="min-w-[700px]"
        container={container || undefined}
      >
        <Form {...form}>
          <form
            className="flex max-h-[90vh] flex-col space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <DialogHeader>
              <DialogTitle>{`Add owner to ${objectName}`}</DialogTitle>
            </DialogHeader>
            <div className="flex h-14 items-center justify-between">
              <div className="flex flex-col">
                <p className="flex space-x-1">
                  <span>{usersFields.length}</span>
                  <span>members selected</span>
                </p>
                <p className="flex space-x-1">
                  <span>{teamsFields.length}</span>
                  <span>teams selected</span>
                </p>
              </div>
              <Button
                disabled={!usersFields.length && !teamsFields.length}
                type="submit"
              >
                Submit
              </Button>
            </div>
            <div className="space-y-2 overflow-auto">
              <Heading size="4">Add user</Heading>
              <div className="px-2">
                <DataTable
                  columns={memberColumns}
                  data={membersWithoutAccessTo}
                  disableQueryParams
                  onRowClick={(row) => row.getToggleSelectedHandler()({})}
                  onSelectionChange={(members) => {
                    form.setValue(
                      "users",
                      members.map((member) => ({
                        id: membersWithoutAccessTo[parseInt(member.id)].user.id,
                      })),
                    );
                  }}
                />
              </div>
              <Separator orientation="horizontal" />
              <Heading size="4">Add team</Heading>
              <div className="px-2">
                <DataTable
                  columns={teamColumns}
                  data={teamsWithoutAccessTo}
                  disableQueryParams
                  onRowClick={(row) => row.getToggleSelectedHandler()({})}
                  onSelectionChange={(teams) => {
                    form.setValue(
                      "teams",
                      teams.map((team) => ({
                        id: teamsWithoutAccessTo[parseInt(team.id)].id,
                      })),
                    );
                  }}
                />
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
