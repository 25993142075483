import z from "zod";

export enum ConstructType {
  Gene = "Gene",
  OligoSet = "OligoSet",
}

export enum ConstructDownloadFormat {
  CSV = "CSV",
  FASTA = "FASTA",
}

export const geneSequenceSchema = z.object({
  root: z.object({
    children: z.array(
      z.object({
        children: z.array(
          z.object({
            style: z.string(),
            text: z.string(),
          }),
        ),
      }),
    ),
  }),
});
