import { OrderStatus } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { useNavigate } from "react-router-dom";

import NewOrder from "./components/new-order";
import { OrderStatusComponent } from "./components/order-status";
import QueueOrderForm from "./components/queue-order-form";

import { useBooleanSearchParam } from "../../../../components/search/useBooleanSearchParam";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import type { TableActionItem } from "../../../../components/ui/data-table/data-table-actions";
import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../components/ui/data-table/filters";
import { Label } from "../../../../components/ui/label";
import { Switch } from "../../../../components/ui/switch";
import { trpc } from "../../../../config/trpc";
import type { AppRouter } from "../../../../config/trpc";
import { OrganizationRoutes } from "../../organization-routes";

type OrderFromList = inferProcedureOutput<AppRouter["order"]["list"]>[number];

const OrderActions = ({ order }: { order: OrderFromList }) => {
  const { status, id } = order;

  const utils = trpc.useUtils();
  const { mutate: reopenOrder } = trpc.order.reopen.useMutation({
    onSuccess() {
      utils.order.list.invalidate();
    },
  });
  const { mutate: cancelOrder } = trpc.order.cancel.useMutation({
    onSuccess() {
      utils.order.list.invalidate();
    },
  });
  const { mutate: archiveOrder } = trpc.order.archive.useMutation({
    onSuccess() {
      utils.order.list.invalidate();
    },
  });

  const queueItem: TableActionItem = {
    children: <QueueOrderForm id={id} />,
    id: "queue",
    preventDefault: true,
  };
  const cancelItem: TableActionItem = {
    children: "Cancel",
    id: "cancel",
    onClick: () => cancelOrder(id),
  };
  const archiveOrderItem: TableActionItem = {
    alertDialog: {
      message:
        "Are you sure you want to archive this Order ? This action cannot be undone.",
      title: "Archive order",
    },
    children: "Archive",
    id: "archive",
    onClick: () => archiveOrder(id),
    preventDefault: true,
  };
  const reopenItem: TableActionItem = {
    children: "Reopen",
    id: "reopen",
    onClick: () => reopenOrder(id),
  };
  const items = {
    [OrderStatus.Canceled]: [reopenItem, archiveOrderItem],
    [OrderStatus.Opportunity]: [queueItem, cancelItem],
    [OrderStatus.Queued]: [cancelItem],
    [OrderStatus.Completed]: [],
    [OrderStatus.InFillNFinish]: [],
    [OrderStatus.InProduction]: [],
    [OrderStatus.Shipping]: [],
  }[status];

  return <TableActions items={items} />;
};

const archivedAtColumn: ColumnDef<OrderFromList> = {
  accessorKey: "archivedAt",
  cell: ({ row }) =>
    row.original.archivedAt
      ? new Date(row.original.archivedAt).toLocaleString()
      : "-",
  header: "Archive date",
  id: "archivedAt",
};
const orderColumns: ColumnDef<OrderFromList>[] = [
  {
    accessorKey: "hubspotQuoteId",
    cell: ({ row }) => row.original.hubspotQuoteId,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "hubspotQuoteId",
    meta: {
      title: "Hubspot quote",
    },
  },
  {
    accessorKey: "netsuiteSalesOrderId",
    cell: ({ row }) => row.original.netsuiteSalesOrderId,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "netsuiteSalesOrderId",
    meta: {
      title: "Netsuite SO#",
    },
  },
  {
    accessorKey: "createdBy.name",
    cell: ({ row }) => row.original.createdBy.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "createdBy",
    meta: {
      title: "Created by",
    },
  },
  {
    accessorKey: "status",
    cell: ({ row }) => OrderStatusComponent[row.original.status],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "status",
    meta: {
      title: "Status",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "createdAt",
    cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
    header: "Creation date",
    id: "createdAt",
  },
  {
    cell: ({ row }) => {
      return <OrderActions order={row.original} />;
    },
    enableHiding: false,
    id: "actions",
  },
];

export default function Orders() {
  const { setSwitchValue: setShowArchivedOrders, isTrue: showArchivedOrders } =
    useBooleanSearchParam("archived");

  const { data: orders } = trpc.order.list.useQuery({
    archived: showArchivedOrders,
  });
  const navigate = useNavigate();

  const columns = showArchivedOrders
    ? orderColumns.slice(0, orderColumns.length - 1).concat(archivedAtColumn)
    : orderColumns;

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between">
        <Heading>Orders</Heading>
        <div className="flex flex-row items-center justify-end space-x-3">
          <div className="flex flex-row items-center space-x-2">
            <Label htmlFor="show-archived-orders">Show archived</Label>
            <Switch
              checked={showArchivedOrders}
              id="show-archived-orders"
              onCheckedChange={setShowArchivedOrders}
            />
          </div>
          <NewOrder />
        </div>
      </div>
      <DataTable
        columns={columns}
        data={orders ?? []}
        onRowClick={(row) => {
          navigate(
            OrganizationRoutes.SERVICE_ORDER.replace(
              ":orderId",
              row.original.id,
            ),
          );
        }}
      />
    </div>
  );
}
