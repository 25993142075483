import type { Role } from "@console/shared";
import { SaveIcon, Undo } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Roles, { RolesEditWithChange } from "../../../../components/logic/roles";
import { Button } from "../../../../components/ui/button";
import { Form } from "../../../../components/ui/form";
import { useGetOrgMemberPossibleRoles } from "../hooks";

export const RolesBlock = ({
  roles,
  editable = false,
  handleChangeAuthorizations,
  title,
}: {
  editable?: boolean;
  handleChangeAuthorizations?: (roles: Role[]) => void;
  roles?: Role[];
  title?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<{ roles: Role[] }>({
    defaultValues: { roles },
  });
  const possiblesRoles = useGetOrgMemberPossibleRoles();

  useEffect(() => {
    form.reset({ roles: roles });
  }, [roles, form]);

  const handleSubmit = (values: { roles: Role[] }) => {
    handleChangeAuthorizations?.(values.roles);
    setIsEditing(false);
  };

  return (
    <div className="bg-card space-y-4 rounded-lg border p-4">
      {isEditing ? (
        <Form {...form}>
          <form
            className="flex flex-row justify-between"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <RolesEditWithChange
              currentRoles={form.watch("roles")}
              handleChange={(roles) => form.setValue("roles", roles)}
              possiblesRoles={possiblesRoles}
              title={title}
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={() => setIsEditing(false)} variant={"secondary"}>
                <Undo />
              </Button>
              <Button type="submit">
                <SaveIcon />
              </Button>
            </div>
          </form>
        </Form>
      ) : (
        <Roles
          editable={editable}
          handleEdit={() => setIsEditing(true)}
          roles={roles}
          title={title}
        />
      )}
    </div>
  );
};
