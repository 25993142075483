import { Link, Outlet } from "react-router-dom";

import { Tabs, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import { OrganizationRoutes } from "../../organization-routes";

const ServiceTabs = [
  {
    label: "Orders",
    path: OrganizationRoutes.SERVICE_ORDERS,
  },
  {
    label: "Oligos",
    path: OrganizationRoutes.SERVICE_OLIGOS,
  },
  {
    label: "Plates",
    path: OrganizationRoutes.SERVICE_PLATES,
  },
];

export default function Service() {
  return (
    <div className="flex flex-col space-y-3">
      <Tabs className="w-[400px]">
        <TabsList>
          {ServiceTabs.map((tab) => (
            <TabsTrigger
              asChild
              key={tab.path}
              value={tab.path.split("/").pop() ?? ""}
            >
              <Link to={tab.path}>{tab.label}</Link>
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <Outlet />
    </div>
  );
}
