export enum AccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum OrganizationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ObjectMembership {
  Assay = "Assay",
  Construct = "Construct",
  Hamilton = "Hamilton",
  Syntax = "Syntax",
}

export enum AuthorizationOwner {
  Member = "Member",
  Team = "Team",
}

export enum Role {
  Administrator = "Administrator",
  OrderCreator = "OrderCreator",
  OrganizationManager = "OrganizationManager",
  ProductionManager = "ProductionManager",
  Support = "Support",
}
