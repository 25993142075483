import type { inferProcedureOutput } from "@trpc/server";
import { useMemo } from "react";

import type { AppRouter } from "../../../../../config/trpc";
import { useUser } from "../../../../../containers/hooks";

export type EnvironmentKit = inferProcedureOutput<
  AppRouter["config"]["kits"]
>[number];
export type AllowedProcess = EnvironmentKit["allowedProcesses"][number];

export const useGetEnvironmentKits = () => {
  const userData = useUser();

  return userData?.kits ?? [];
};

export const useGetKitLabels = () => {
  const kits = useGetEnvironmentKits();
  const kitToLabel = useMemo(() => {
    return kits.reduce(
      (acc, kit) => {
        acc[kit.id] = kit.label;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [kits]);

  return (kitId: string) => kitToLabel[kitId] ?? kitId;
};
