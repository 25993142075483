export enum NotificationChannel {
  EMAIL = "EMAIL",
  SMS = "SMS",
  TEAMS = "TEAMS",
}

export enum NotificationType {
  RunCompleted = "RunCompleted",
  RunCreated = "RunCreated",
}
