import type { ColumnDef } from "@tanstack/react-table";
import equal from "fast-deep-equal";
import { ArrowRight } from "lucide-react";
import React, { useCallback, useMemo } from "react";
import { useDrag } from "react-dnd";

import type { Oligo } from "./types";
import { DragObjects } from "./types";
import { getWellId } from "./utils";
import { useWorkflowBuildContext } from "./workflow-build-context";

import { Button } from "../../../../../components/ui/button";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";
import type { TableRow } from "../../../../../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { cn } from "../../../../../lib/utils";
import {
  CheckCircleGreen,
  CheckIfTrueElseCross,
  CrossCircleRed,
} from "../../../../admin/organizations/components/ui";

type OligoWithDerivedInfo = Oligo & {
  isSelected: boolean;
  wellsOfOligo: {
    plateId: string;
    wellIndex: string;
  }[];
};

const scrollToPlateWell = (plateId: string, wellIndex: string) => {
  const element = document.getElementById(getWellId(plateId, wellIndex));
  if (!element) {
    return;
  }
  element.scrollIntoView({
    behavior: "auto",
    block: "center",
    inline: "center",
  });
};

const OligoWellAssigment = ({
  wellIndex,
  plateName,
  plateId,
}: {
  plateId: string;
  plateName: string;
  wellIndex: string;
}) => {
  const handleScrollToPlateWell = useCallback(() => {
    scrollToPlateWell(plateId, wellIndex);
  }, [plateId, wellIndex]);

  return (
    <li className="flex flex-row items-center justify-between space-x-2">
      <div className="flex flex-row items-center">
        <p>{plateName}</p>
        <p>-</p>
        <p>{wellIndex}</p>
      </div>
      <Button onClick={handleScrollToPlateWell} size={"icon"} variant={"ghost"}>
        <ArrowRight />
      </Button>
    </li>
  );
};

const OligoAssignmentTrigger = ({ wellCount }: { wellCount: number }) => {
  return (
    <div className="relative w-6">
      <CheckCircleGreen />
      {wellCount > 1 && (
        <div className="absolute -right-2 -top-1 text-xs">
          <span>{wellCount}</span>
        </div>
      )}
    </div>
  );
};

const OligoAssignments = ({
  wellsOfOligo,
}: {
  wellsOfOligo: OligoWithDerivedInfo["wellsOfOligo"];
}) => {
  const wellsLength = wellsOfOligo.length;
  const hasWells = wellsLength > 0;
  const { plateIdToPlate } = useWorkflowBuildContext();

  if (!hasWells) {
    return <CrossCircleRed />;
  }

  return (
    <Popover>
      <PopoverTrigger className="col-span-3 flex flex-row space-x-1">
        <OligoAssignmentTrigger wellCount={wellsLength} />
      </PopoverTrigger>
      <PopoverContent className="w-96 text-sm">
        <ul className="flex flex-col">
          {wellsOfOligo.map((w) => {
            return (
              <OligoWellAssigment
                key={w.plateId + w.wellIndex}
                plateId={w.plateId}
                plateName={plateIdToPlate.get(w.plateId)?.name ?? ""}
                wellIndex={w.wellIndex}
              />
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
};

const oligoColumns: ColumnDef<OligoWithDerivedInfo>[] = [
  {
    accessorKey: "sequence",
    cell: (info) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <p className="max-w-[90px] truncate font-mono text-xs">
            {info.row.original.sequence}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-[40vw] font-mono text-xs">
            {info.row.original.sequence}
          </p>
        </TooltipContent>
      </Tooltip>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "sequence",
    meta: {
      title: "Sequence",
    },
  },
  {
    accessorKey: "name",
    cell: (info) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <p className="max-w-[90px] truncate text-xs">
            {info.row.original.name}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-xs">{info.row.original.name}</p>
        </TooltipContent>
      </Tooltip>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "order",
    cell: (info) => (
      <p>
        <span>{info.row.original.order ?? "-"}</span>
        <span>|</span>
        <span>{info.row.original.platePositioningExpected ?? "-"}</span>
      </p>
    ),
    header: "Order",
    id: "platePositioningExpected",
  },
  {
    accessorFn(row) {
      return row.wellsOfOligo.length > 0;
    },
    cell: (info) => (
      <OligoAssignments wellsOfOligo={info.row.original.wellsOfOligo} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "placed",
    meta: {
      booleanFilter: true,
      title: "Assign.",
    },
  },
  {
    accessorFn(row) {
      return row.wellsOfOligo.length > 0;
    },
    cell: (info) => (
      <CheckIfTrueElseCross value={info.row.original.synthesized} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "synthesized",
    meta: {
      booleanFilter: true,
      title: "Syn.",
    },
  },
];

const areOligosEqual = (
  prevOligo: OligoWithDerivedInfo,
  nextOligo: OligoWithDerivedInfo,
) => equal(prevOligo, nextOligo);

const TableRowOligoWorkflow = React.memo(
  React.forwardRef<
    HTMLTableRowElement,
    React.HTMLAttributes<HTMLTableRowElement> & {
      original: OligoWithDerivedInfo;
    }
  >(({ className, original, ...props }, ref) => {
    const { selectedOligoIds, handleClickOligo } = useWorkflowBuildContext();
    const { id, isSelected } = original;

    const [collected, drag] = useDrag(
      () => ({
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
        item: () => {
          if (selectedOligoIds.includes(id)) {
            return {
              ids: selectedOligoIds,
            };
          }
          return {
            id: id,
          };
        },
        type: DragObjects.Oligo,
      }),
      [selectedOligoIds, id],
    );

    const onMouseEnter = () => {
      document.querySelectorAll(`[data-group='${id}']`).forEach((el) => {
        el.classList.add("ring");
      });
    };

    const onMouseLeave = () => {
      document.querySelectorAll(`[data-group='${id}']`).forEach((el) => {
        el.classList.remove("ring");
      });
    };

    return (
      <tr
        className={cn(
          "data-[state=selected]:bg-muted cursor-grab border-b transition-colors hover:ring",
          className,
          collected.isDragging && "bg-slate-400",
          isSelected && "bg-slate-200",
        )}
        data-group={id}
        id={id}
        onClick={(e) => handleClickOligo(e, id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={(node) => {
          drag(node);
          if (typeof ref === "function") {
            ref(node);
          }
        }}
        {...props}
      />
    );
  }),
  (prevProps, nextProps) =>
    areOligosEqual(prevProps.original, nextProps.original),
);
TableRowOligoWorkflow.displayName = "TableRowOligoWorkflow";

export default function WorkflowOligos() {
  const {
    workflow: { oligos },
    oligoToWellsMap,
    selectedOligoIds,
  } = useWorkflowBuildContext();

  const oligosWithDerivedInfo = useMemo(
    () =>
      oligos.map((o) => ({
        ...o,
        isSelected: selectedOligoIds.includes(o.id),
        wellsOfOligo: oligoToWellsMap.get(o.id) ?? [],
      })),
    [oligoToWellsMap, oligos, selectedOligoIds],
  );

  return (
    <DataTable
      RowComponent={TableRowOligoWorkflow as typeof TableRow}
      columns={oligoColumns}
      data={oligosWithDerivedInfo}
      tableContainerClassName="max-h-[80vh] overflow-scroll p-2 pr-4 [&_td]:p-1"
      useBorders={false}
      usePagination={false}
    />
  );
}
