import {
  WellErrorSeverity,
  WellErrorType,
  Normalization,
  ModificationStatusToLabel,
} from "@console/shared";
import {
  AlertTriangle,
  LucideCircleSlash2,
  Trash2Icon,
  XCircle,
} from "lucide-react";

import { PurityFlagToName } from "./constants";
import type { Display } from "./display";
import { UnusualQuantification, UnusualQuantificationIcon } from "./legends";
import type { PlateKit, WorkflowWell } from "./types";
import {
  getColorsFromPurity,
  getWellColors,
} from "./useGetWellBackgroundColor";

import { Button } from "../../../../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";
import { cn } from "../../../../../../lib/utils";
import {
  getSequenceLength,
  parseInitiatorDNASequence,
} from "../../../../../../utils/parser";
import PickConcentration from "../../../assay/components/pick-concentration";
import {
  BasicInput,
  SequenceInput,
} from "../../../construct/components/sequence-input";

const ErrorSeverityToIcon: Record<WellErrorSeverity, JSX.Element> = {
  [WellErrorSeverity.Error]: <XCircle size={24} />,
  [WellErrorSeverity.Warning]: <AlertTriangle size={24} />,
};

const ErrorTypeToLabel: Record<WellErrorType, string> = {
  [WellErrorType.Biosecurity]: "Biosecurity problem",
  [WellErrorType.Click]: "Click problem",
  [WellErrorType.Length]: "Length problem",
  [WellErrorType.UnknownNucleotide]: "Unknown nucleotide",
  [WellErrorType.Purity]: "Estimated purity problem",
};

const SmallColoredDotFromGradient = ({
  gradient,
  position,
}: {
  gradient: string;
  position: number;
}) => {
  return (
    <span
      className="bg-well mr-1 h-2 w-2 rounded-full"
      style={{
        backgroundImage: gradient,
        backgroundPosition: `${position}%`,
      }}
    />
  );
};

export const SmallColoredDot = ({ className }: { className?: string }) => {
  return <span className={cn("mr-1 h-2 w-2 rounded-full", className)} />;
};

const getGradient =
  (well: WorkflowWell, kitProperties: PlateKit) => (display: Display) => {
    return getWellColors(well, display, kitProperties)["useGradient"];
  };

const SidebarResultSection = ({
  well,
  kitProperties,
}: {
  kitProperties: PlateKit;
  well: WorkflowWell;
}) => {
  const { result } = well;
  const gradientGetter = getGradient(well, kitProperties);
  const useGradientConcentration = gradientGetter("concentration");
  const useGradientMeasuredConcentration = gradientGetter(
    "measuredConcentration",
  );
  const useGradientVolume = gradientGetter("volume");
  const useGradientYield = gradientGetter("yield");
  const useGradientModifications = gradientGetter("modification");

  if (!result) {
    return null;
  }
  const {
    concentration,
    yield: yieldValue,
    totalVolume,
    sequenceModificationStatus,
  } = result;

  const unusualQuantification = Boolean(well.result?.yieldError);

  return (
    <>
      <p className="col-span-1">Normalization</p>
      <p>{well.normalization}</p>
      <p className="col-span-1">Concentration (Target)</p>
      <p className="col-span-1 flex items-center space-x-1">
        {well.normalization !== Normalization.None &&
          useGradientConcentration && (
            <SmallColoredDotFromGradient
              gradient={useGradientConcentration.gradient}
              position={useGradientConcentration.position}
            />
          )}
        {well.normalization !== Normalization.None ? (
          <span>{`${well.result?.concentrationDesired.toFixed(1)} µM`}</span>
        ) : (
          <span>-</span>
        )}
      </p>
      <p className="col-span-1">Concentration (Actual)</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientMeasuredConcentration && (
          <SmallColoredDotFromGradient
            gradient={useGradientMeasuredConcentration.gradient}
            position={useGradientMeasuredConcentration.position}
          />
        )}
        <span>{concentration.toFixed(1)} µM</span>
      </p>

      <p className="col-span-1">Mods</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientModifications && (
          <SmallColoredDotFromGradient
            gradient={useGradientModifications.gradient}
            position={useGradientModifications.position}
          />
        )}
        <span>{ModificationStatusToLabel[sequenceModificationStatus]}</span>
      </p>

      <p className="col-span-1">Actual volume</p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientVolume && (
          <SmallColoredDotFromGradient
            gradient={useGradientVolume.gradient}
            position={useGradientVolume.position}
          />
        )}
        <span>{Math.floor(totalVolume)} µL</span>
      </p>

      <p className="col-span-1 flex flex-row items-center space-x-1">
        {unusualQuantification && <UnusualQuantificationIcon />}
        <span>Yield</span>
      </p>
      <p className="col-span-1 flex items-center space-x-1">
        {useGradientYield && (
          <SmallColoredDotFromGradient
            gradient={useGradientYield.gradient}
            position={useGradientYield.position}
          />
        )}
        <span>{Math.floor(yieldValue)} pmol</span>
      </p>
    </>
  );
};

export const PlateSidebarWell = ({
  isEditable,
  well,
  handleChangeWell,
  handleDeleteWell,
  handleResetWell,
  kitProperties,
}: {
  handleChangeWell: (index: string, well: WorkflowWell) => void;
  handleDeleteWell: (index: string) => void;
  handleResetWell: (index: string) => void;
  isEditable: boolean;
  kitProperties: PlateKit;
  well: WorkflowWell;
}) => {
  const defaultConcentration = kitProperties.concentration.min;

  const { errors, index, name, normalization, purityFlag, result, sequence } =
    well;
  const hasErrors = errors.length > 0;
  const { sequence: dnaSequence, initiatorSequence } =
    parseInitiatorDNASequence(sequence);
  const hasInitiator = Boolean(initiatorSequence);

  const handleApplySequenceChange = (newSequence: string) => {
    handleChangeWell(index, {
      ...well,
      sequence: hasInitiator
        ? `${initiatorSequence}+${newSequence}`
        : newSequence,
    });
  };

  const handleApplyInitiatorSequenceChange = (newInitiator: string) => {
    handleChangeWell(index, {
      ...well,
      sequence: `${newInitiator}+${dnaSequence}`,
    });
  };

  const handleRemoveInitiatorSequence = () => {
    handleChangeWell(index, {
      ...well,
      sequence: dnaSequence,
    });
  };

  const handleApplyNameChange = (name: string) => {
    handleChangeWell(index, { ...well, name });
  };

  const handleApplyConcentrationChange = (
    normalization: Normalization,
    concentration?: number,
  ) => {
    handleChangeWell(index, {
      ...well,
      expectedConcentration: concentration,
      normalization,
    });
  };

  const { background, text } = getColorsFromPurity(well.purityFlag);
  const unusualQuantification = Boolean(well.result?.yieldError);

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between">
        <span className="font-bold">{`Inspector (${index})`}</span>
        <div className="flex flex-row space-x-2">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                disabled={!isEditable}
                onClick={() => {
                  handleResetWell(index);
                }}
                variant={"ghost"}
              >
                <LucideCircleSlash2 />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Reset well</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                disabled={!isEditable}
                onClick={() => {
                  handleDeleteWell(index);
                }}
                variant={"ghost"}
              >
                <Trash2Icon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Remove well</TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div className="font-medium">
        {unusualQuantification && <UnusualQuantification />}
      </div>
      {hasErrors && (
        <div className="flex flex-col space-y-2 rounded-lg bg-red-200 p-2">
          <div className="text-center">
            <p className="font-bold text-red-600">Errors and Warnings</p>
          </div>
          <ul className="text-sm">
            {errors.map((error) => {
              return (
                <li
                  className="grid grid-cols-8 items-start gap-1 space-x-2"
                  key={error.message}
                >
                  <p className="col-span-1">
                    {ErrorSeverityToIcon[error.severity]}
                  </p>
                  <p className="col-span-2 font-medium">
                    {ErrorTypeToLabel[error.type]}
                  </p>
                  <p className="col-span-5">{error.message}</p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className="space-y-1">
        <p className="font-medium">Sequence</p>
        <SequenceInput
          disabled={!isEditable}
          onBlur={() => {}}
          onChange={handleApplySequenceChange}
          value={dnaSequence}
        />
      </div>
      {initiatorSequence && (
        <div className="space-y-1">
          <div className="flex flex-row justify-between">
            <p className="font-medium">Initiator sequence</p>
            <Button
              onClick={handleRemoveInitiatorSequence}
              size={"xs"}
              variant={"outline"}
            >
              <Trash2Icon size={14} />
            </Button>
          </div>
          <SequenceInput
            disabled={!isEditable}
            onBlur={() => {}}
            onChange={handleApplyInitiatorSequenceChange}
            value={initiatorSequence}
          />
        </div>
      )}
      <div className="space-y-1">
        <p className="font-medium">Sequence name</p>
        <BasicInput
          disabled={!isEditable}
          onBlur={() => {}}
          onChange={handleApplyNameChange}
          value={name}
        />
      </div>
      <div className="grid grid-cols-2 gap-2 font-medium">
        <p className="col-span-1">Length</p>
        <p>{getSequenceLength(well.sequence)}</p>
      </div>

      {!result && (
        <PickConcentration
          currentConcentration={
            well.expectedConcentration ?? defaultConcentration
          }
          handleApplyConcentrationChange={handleApplyConcentrationChange}
          isEditable={isEditable}
          kitConcentrationMax={kitProperties.concentration.max}
          kitConcentrationMin={kitProperties.concentration.min}
          normalization={normalization}
        />
      )}

      <div className="grid grid-cols-2 gap-2 font-medium">
        {result && (
          <SidebarResultSection kitProperties={kitProperties} well={well} />
        )}
        <p className="col-span-1">Complexity Score</p>
        <p className="col-span-1 flex items-center space-x-1">
          <SmallColoredDot className={`${background} ${text}`} />
          <span>{PurityFlagToName[purityFlag]}</span>
        </p>
      </div>
    </div>
  );
};
