import { StepType } from "@console/shared";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import type {
  PlateConsumables,
  Reagent,
  WorkflowStepTRPC,
} from "./components/plate/types";
import RunReagentsRack from "./run-reagents-rack";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { SimpleTable } from "../../../../components/ui/simple-table";

const plateColumnHelper = createColumnHelper<PlateConsumables["plates"][0]>();

const plateColumns = [
  plateColumnHelper.accessor("type", {
    header: "Plate Type",
  }),
  plateColumnHelper.accessor("size", {
    header: "Size",
  }),
  plateColumnHelper.accessor("uniqueNumber", {
    header: "Unique #",
  }),
  plateColumnHelper.accessor("partNumber", {
    header: "Part #",
  }),
  plateColumnHelper.accessor("lotNumber", {
    header: "Lot #",
  }),
  plateColumnHelper.display({
    cell: ({ row: { original } }) => {
      if (original.type === "OLIGO") {
        return "N/A";
      }
      return new Date(original.expirationDate).toLocaleDateString();
    },
    header: "Expiration",
    id: "expirationDate",
  }),
];
const Plates = ({ plates }: { plates: PlateConsumables["plates"] }) => {
  const table = useReactTable({
    columns: plateColumns,
    data: plates,
    getCoreRowModel: getCoreRowModel(),
  });

  return <SimpleTable table={table} />;
};

const reagentColumnHelper = createColumnHelper<Reagent>();

const accessoryColumns = [
  reagentColumnHelper.accessor("displayName", {
    header: "Reagent name",
  }),
  reagentColumnHelper.accessor("partNumber", {
    header: "Part #",
  }),
  reagentColumnHelper.accessor("lotNumber", {
    header: "Lot #",
  }),
  reagentColumnHelper.display({
    cell: ({ row: { original } }) => {
      return new Date(original.expirationDate).toLocaleDateString();
    },
    header: "Expiration",
    id: "expirationDate",
  }),
  reagentColumnHelper.accessor("daysOnSystem", {
    header: "Days on system",
  }),
  reagentColumnHelper.accessor("amountUsed", {
    header: "Amount used (mL)",
  }),
];

const Accessories = ({ accessories }: { accessories: Reagent[] }) => {
  const table = useReactTable({
    columns: accessoryColumns,
    data: accessories,
    getCoreRowModel: getCoreRowModel(),
  });

  return <SimpleTable table={table} />;
};

export default function RunReagents({ step }: { step: WorkflowStepTRPC }) {
  if (step.stepType !== StepType.OligoSynthesis) {
    return null;
  }

  const consumables = step?.plate?.run?.report?.consumables;

  if (!consumables) {
    return null;
  }

  const accessories = consumables.reagents.filter(
    (reagent) => reagent.isAccessory,
  );

  return (
    <Card id="run-reagents">
      <CardHeader>
        <CardTitle>
          <span>Consumables use</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="rounded-lg border p-2">
          <h3 className="font-bold">Plates</h3>
          <Plates plates={consumables.plates} />
        </div>
      </CardContent>
      {accessories.length > 0 && (
        <CardContent className="space-y-4">
          <div className="rounded-lg border p-2">
            <h3 className="font-bold">Accessories</h3>
            <Accessories accessories={accessories} />
          </div>
        </CardContent>
      )}
      <RunReagentsRack reagents={consumables.reagents} />
    </Card>
  );
}
