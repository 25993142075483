import { WellErrorSeverity } from "@console/shared";
import { PlayCircle, RocketIcon } from "lucide-react";
import { useState } from "react";

import type { PlateFromWorkflow } from "./plate/types";
import { getPlateProblems } from "./plateErrors";
import SyntaxInstrumentOption from "./syntax-instrument-option";
import { useSelectSyntaxProcess } from "./useSelectSyntaxProcess";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { Label } from "../../../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { Separator } from "../../../../../components/ui/separator";
import { LoadingSpinner } from "../../../../../components/ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";
import { useGetMyOrganization } from "../../../../settings/organization-settings/hooks";

export default function ChooseSyntaxInstrument({
  plate,
  disabled = false,
}: {
  disabled?: boolean;
  plate: PlateFromWorkflow;
}) {
  const [open, setOpen] = useState(false);
  if (plate.run) {
    return null;
  }
  const isPlate = Boolean(plate) && plate.wells.length > 0;
  const plateProblems = plate ? getPlateProblems(plate) : [];
  const plateErrors = plateProblems.filter(
    (p) => p.severity === WellErrorSeverity.Error,
  );
  const plateHasError = Boolean(isPlate && plateErrors.length > 0);
  const isUploadToInstrumentDisabled = !isPlate || plateHasError || disabled;

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <Tooltip>
        <TooltipTrigger>
          <DialogTrigger asChild disabled={disabled}>
            <Button
              className="flex flex-row items-center space-x-2"
              disabled={isUploadToInstrumentDisabled}
            >
              <PlayCircle />
              <span>Upload to syntax</span>
            </Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>
          {plateHasError ? (
            <div>
              <h3 className="font-bold">Cannot upload to syntax</h3>
              <ul className="list-disc pl-4">
                {plateErrors.map((error, i) => (
                  <li key={i}>{error.message}</li>
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <h3 className="font-bold">Upload to syntax</h3>
              <p>Upload this plate to an instrument to continue</p>
            </div>
          )}
        </TooltipContent>
      </Tooltip>
      <ChooseSyntaxInstrumentDialog plate={plate} setOpen={setOpen} />
    </Dialog>
  );
}

function ChooseSyntaxInstrumentDialog({
  plate,
  setOpen,
}: React.PropsWithChildren<{
  plate: PlateFromWorkflow;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>) {
  const { toast } = useToast();
  const [selectedInstrumentId, setSelectedInstrumentId] = useState("");
  const [error, setError] = useState("");
  const [selectedProcess, setSelectedProcess] = useState<null | string>(null);
  const organization = useGetMyOrganization();
  const { data: instruments } = trpc.instrument.syntax.list.useQuery(
    {
      organizationId: organization?.id ?? "",
    },
    {
      enabled: !!organization?.id,
    },
  );
  const { mutate: createRun, isPending } =
    trpc.assay.steps.plate.run.create.useMutation({
      onError(error) {
        setError(error.message);
      },
      onSuccess() {
        setOpen(false);
        toast({
          description: "The run has been created",
          title: "Run created",
          variant: "default",
        });
      },
    });

  const SelectProcessNode = useSelectSyntaxProcess(plate, (process) => {
    setSelectedProcess(process);
  });

  const availableInstruments = instruments ?? [];
  const noAvailableInstruments = availableInstruments.length === 0;
  const selectedInstrument = availableInstruments.find(
    (instrument) => instrument.id === selectedInstrumentId,
  );

  const handleSubmitForm = () => {
    if (!selectedProcess) {
      setError("Please select a process");
      return;
    }
    setError("");
    createRun({
      instrumentId: selectedInstrumentId,
      plateId: plate.id,
      processId: selectedProcess,
    });
  };

  return (
    <DialogContent
      className={cn(
        "flex flex-col space-y-2",
        isPending && "pointer-events-none opacity-50",
      )}
      onEscapeKeyDown={(e) => {
        e.preventDefault();
      }}
    >
      {isPending && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <LoadingSpinner />
        </div>
      )}
      <DialogHeader>
        <DialogTitle>Create print job</DialogTitle>
        <DialogDescription>
          Configure the instrument, processes to use
        </DialogDescription>
      </DialogHeader>
      <div>
        <p>Summary</p>
        <div className="text-sm">
          <p>Cycles: {plate.cycles}</p>
          <p>Kit: {plate.kitLabel}</p>
        </div>
      </div>
      <Separator />
      <div>
        <Label htmlFor="instrument-selection">
          Pick an instrument to run this plate on
        </Label>
        {noAvailableInstruments ? (
          <p className="text-destructive">
            There are no instruments available to run this plate on
          </p>
        ) : (
          <Select
            disabled={noAvailableInstruments || isPending}
            onValueChange={(value) => {
              setSelectedInstrumentId(value);
            }}
            value={selectedInstrumentId}
          >
            <SelectTrigger>
              <SelectValue asChild id="instrument-selection">
                {selectedInstrument ? (
                  <SyntaxInstrumentOption
                    instrument={selectedInstrument}
                    plate={plate}
                  />
                ) : (
                  <span>Choose the instrument</span>
                )}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {availableInstruments?.map((instrument) => (
                <SelectItem
                  asChild
                  className="w-full cursor-pointer hover:bg-slate-100"
                  key={instrument.id}
                  value={instrument.id}
                >
                  <SyntaxInstrumentOption
                    instrument={instrument}
                    plate={plate}
                  />
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      </div>
      <Separator />
      {SelectProcessNode}
      <p className="text-destructive">{error}</p>
      <DialogFooter>
        <Tooltip>
          <TooltipTrigger>
            <Button
              aria-label="Create run"
              disabled={
                noAvailableInstruments ||
                !selectedInstrumentId ||
                !selectedProcess
              }
              onClick={handleSubmitForm}
              type="submit"
            >
              <RocketIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {noAvailableInstruments
              ? "There are no instruments available to run this plate on"
              : !selectedInstrumentId
                ? "Please select an instrument"
                : !selectedProcess
                  ? "Please select a process"
                  : "Create run"}
          </TooltipContent>
        </Tooltip>
      </DialogFooter>
    </DialogContent>
  );
}
