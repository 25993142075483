import { StepType } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import { ArrowRightFromLine } from "lucide-react";
import { useNavigate } from "react-router-dom";

import Plate from "./components/plate";
import type { WorkflowStepTRPC } from "./components/plate/types";
import StepActions from "./components/step-actions";
import WorkflowStepName from "./components/workflow-step-name";
import RunReagents from "./run-reagents";
import { useGetWorkflowStep } from "./useGetWorkflowStep";
import { GeneProgress } from "./workflow-progress";

import { Button } from "../../../../components/ui/button";
import { trpc } from "../../../../config/trpc";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { OrganizationRoutes } from "../../organization-routes";
import useGetAssay from "../assay/useGetAssay";

export default function WorkflowStepWrapper() {
  const { data: workflowStep, isPending, error } = useGetWorkflowStep();

  if (isPending) {
    return null;
  }

  if (error) {
    return (
      <div>
        <Heading>Error retrieving workflow</Heading>
      </div>
    );
  }

  return <WorkflowStep workflowStep={workflowStep} />;
}

function WorkflowStep({ workflowStep }: { workflowStep: WorkflowStepTRPC }) {
  useScrollToTop();
  const navigate = useNavigate();
  const { data: assay } = useGetAssay();
  const { id, name, stepType } = workflowStep;
  const plate =
    stepType === StepType.OligoSynthesis ? workflowStep.plate : null;
  const isRunCreated = Boolean(plate?.run);
  const isEditable =
    !isRunCreated &&
    workflowStep.stepType === StepType.OligoSynthesis &&
    !workflowStep.locked;

  if (!assay) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <WorkflowStepName
          currentName={name}
          isEditable={isEditable}
          workflowStepId={id}
        />
        <div className="flex flex-row items-center space-x-3">
          <Button
            className="flex flex-row items-center space-x-1"
            onClick={() => {
              navigate(
                OrganizationRoutes.ASSAY.replace(
                  ":assayId",
                  workflowStep.workflow.assayId,
                ),
              );
            }}
            variant={"outline"}
          >
            <span>Assay</span>
            <ArrowRightFromLine />
          </Button>
          <StepActions assay={assay} step={workflowStep} />
        </div>
      </div>

      {stepType === StepType.HamiltonAssembly && (
        <GeneProgress gene={workflowStep.gene} />
      )}
      {plate ? (
        <>
          <WorkflowPlate
            isEditable={isEditable && !assay?.archived}
            plateId={plate.id}
          />
          <RunReagents step={workflowStep} />
        </>
      ) : null}
    </div>
  );
}

export function WorkflowPlate({
  plateId,
  isEditable,
}: {
  isEditable: boolean;
  plateId: string;
}) {
  const { data: plate } = trpc.plate.get.useQuery(plateId, {
    enabled: !!plateId,
  });
  const utils = trpc.useUtils();
  const onPlateChange = () => {
    utils.assay.steps.get.invalidate();
  };

  if (!plate) {
    return null;
  }

  return (
    <Plate
      isEditable={isEditable}
      onPlateChange={onPlateChange}
      plate={plate}
    />
  );
}
