import { z } from "zod";

export enum InstrumentHardwareVersion {
  HW_201 = "201",
  HW_301 = "301",
}

export const createSyntaxInstrumentSchema = z.object({
  canBeTriggeredExternally: z.boolean().default(false),
  name: z.string().min(1),
  serialNumber: z.string().min(1),
  version: z.nativeEnum(InstrumentHardwareVersion),
});

export const updateSyntaxInstrumentSchema = createSyntaxInstrumentSchema.and(
  z.object({ instrumentId: z.string() }),
);

export enum InstrumentStatus {
  Available = "Available",
  Busy = "Busy",
  Unavailable = "Unavailable",
}

export enum ChillerDoorStatus {
  Closed = "Closed",
  Open = "Open",
  Unknown = "Unknown",
}

export enum ChillerTrayStatus {
  Missing = "Missing",
  Present = "Present",
}

export enum ChillerSippersStatus {
  Lowered = "Lowered",
  Moving = "Moving",
  Raised = "Raised",
}

export enum DoorStatus {
  ClosedLocked = "ClosedLocked",
  ClosedUnlocked = "ClosedUnlocked",
  Open = "Open",
  Unknown = "Unknown",
}
