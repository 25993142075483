import type { ConstructDownloadFormat } from "@console/shared";

import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useDownloadFile } from "../../../../../utils/useDownload";

export const useDownloadConstruct = (constructId: string | undefined) => {
  const { toast } = useToast();
  const downloadFile = useDownloadFile();
  const { mutate: downloadConstruct } = trpc.construct.download.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error when downloading construct",
        variant: "destructive",
      });
    },
    onSuccess(url) {
      try {
        downloadFile(url, `construct.csv`);
      } catch (error) {
        const message =
          "message" in (error as any) ? (error as any).message : "";
        toast({
          description: message,
          title: "Error when downloading construct",
          variant: "destructive",
        });
      }
    },
  });

  const handleDownloadConstruct = (downloadFormat: ConstructDownloadFormat) => {
    if (!constructId) {
      return;
    }
    downloadConstruct({
      constructId,
      format: downloadFormat,
    });
  };

  return handleDownloadConstruct;
};
