import { Badge } from "../../../../../components/ui/badge";
import useNewPlateContext from "../hooks/useNewPlateContext";

export default function NewPlateLegend() {
  const { orderColors, oligos } = useNewPlateContext();

  const assignedOrderIds = Array.from(
    new Set(
      oligos
        .filter((o) => o.isCurrentlyAssigned)
        .map((o) => o.orderSOId)
        .filter((id): id is string => Boolean(id)),
    ),
  ).sort();

  return (
    <div className="space-x-2 space-y-2">
      {assignedOrderIds.map((orderId) => {
        return (
          <Badge
            className="text-black"
            key={orderId}
            style={{
              backgroundColor: orderColors.get(orderId),
            }}
          >
            {orderId}
          </Badge>
        );
      })}
    </div>
  );
}
