import { ShoppingBasket, SaveIcon, Upload } from "lucide-react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../../containers/user/hooks";
import { arrayBufferTobase64 } from "../../../../../utils/useDownload";

export default function NewOrder() {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [hubspotQuoteId, setHubspotDealId] = useState<string | null>(null);
  const {
    order: { create: canCreateOrder },
  } = useGetUIAuthorizedCreations();

  const utils = trpc.useUtils();
  const { mutate: createOrderRequest, isPending } =
    trpc.order.create.useMutation({
      onError(error) {
        setOpen(false);
        toast({
          description: (
            <p>
              <span>An error occurred while creating the order</span>
              <span>{error.message}</span>
            </p>
          ),
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          description: "The order was successfully created",
          title: "Order created",
          variant: "success",
        });
        setOpen(false);
        utils.order.list.invalidate();
      },
    });

  const handleSubmit = () => {
    if (!file || !hubspotQuoteId) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      createOrderRequest({
        excelBase64: base64,
        hubspotQuoteId,
      });
    };

    submit();
  };

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: false,
    noDrag: false,
    onDrop,
    useFsAccessApi: false,
  });

  if (!canCreateOrder) {
    return null;
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button className="flex flex-row items-center space-x-1">
          <span>New order</span>
          <ShoppingBasket />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Create new order</DialogTitle>
        </DialogHeader>
        <div {...getRootProps()}>
          <input {...getInputProps()} data-testid={"drop-order"} />
          <Button className="space-x-2" variant={"secondary"}>
            <span>Upload excel file</span>
            <Upload />
          </Button>
        </div>
        {file && (
          <p className="flex flex-row space-x-1">
            <span>File uploaded: </span>
            <span className="italic">{file.name}</span>
          </p>
        )}
        <div>
          <Label htmlFor="hubspotDealID">Hubspot quote Id</Label>
          <Input
            id="hubspotDealID"
            onChange={(e) => setHubspotDealId(e.target.value)}
            required
            value={hubspotQuoteId ?? ""}
          />
        </div>

        <div className="flex flex-row justify-end space-x-2">
          <Button
            disabled={!file || !hubspotQuoteId}
            isLoading={isPending}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
