export enum ReagentGroups {
  Nucleotides = "NEZ",
  PostSynthesisProcess = "PSP",
  Synthesis = "SYN",
  Unknown = "Unknown",
  Wash = "WSH",
}
export const ReagentGroupToTitle: Record<string, string> = {
  [ReagentGroups.Nucleotides]: "Nucleotides",
  [ReagentGroups.PostSynthesisProcess]: "Post Synthesis Process",
  [ReagentGroups.Synthesis]: "Synthesis",
  [ReagentGroups.Unknown]: "Unknown",
  [ReagentGroups.Wash]: "Wash",
};
