import { SaveIcon, Upload } from "lucide-react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import type { PlateFromWorkflow } from "./plate/types";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { arrayBufferTobase64 } from "../../../../../utils/useDownload";

export default function UploadSynthesisFile({
  plate,
}: {
  plate: PlateFromWorkflow;
}) {
  const { id, prototypeName } = plate;
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { mutate: uploadSynthesisFile, isPending } =
    trpc.order.productionPlates.uploadSynthesisResults.useMutation({
      onSuccess() {
        toast({
          description: "The synthesis file was successfully uploaded",
          title: "Synthesis file uploaded",
          variant: "success",
        });
        setOpen(false);
      },
    });

  const handleSubmit = () => {
    if (!file) {
      return;
    }
    const submit = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const base64 = arrayBufferTobase64(arrayBuffer);
      uploadSynthesisFile({
        file: base64,
        plateId: id,
      });
    };

    submit();
  };

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xlsm",
      ],
    },
    multiple: false,
    noDrag: false,
    onDrop,
    useFsAccessApi: false,
  });

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          className="flex flex-row items-center space-x-1"
          disabled={!prototypeName}
          variant={"secondary"}
        >
          <span>Upload synthesis order</span>
          <Upload />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Create new order</DialogTitle>
        </DialogHeader>
        <div {...getRootProps()}>
          <input {...getInputProps()} data-testid={"drop-order"} />
          <Button className="space-x-2" variant={"secondary"}>
            <span>Upload synthesis file</span>
            <Upload />
          </Button>
        </div>
        {file && (
          <p className="flex flex-row space-x-1">
            <span>File uploaded: </span>
            <span className="italic">{file.name}</span>
          </p>
        )}
        <div className="flex flex-row justify-end space-x-2">
          <Button disabled={!file} isLoading={isPending} onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
