import { PlateStatus } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Link, useNavigate } from "react-router-dom";

import { PlateStatusComponent } from "./components/plate-status";

import { DataTable } from "../../../../components/ui/data-table/data-table";
import type { TableActionItem } from "../../../../components/ui/data-table/data-table-actions";
import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import type { AppRouter } from "../../../../config/trpc";
import { trpc } from "../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import { OrganizationRoutes } from "../../organization-routes";

type PlateFromList = inferProcedureOutput<
  AppRouter["order"]["productionPlates"]["list"]
>[number];

const PlateActions = ({ plate }: { plate: PlateFromList }) => {
  const { status, id } = plate;
  const utils = trpc.useUtils();
  const {
    order: { change: canCancelPlate },
  } = useGetUIAuthorizedCreations();

  const { mutate: cancelPlate } =
    trpc.order.productionPlates.cancel.useMutation({
      onSuccess() {
        utils.order.productionPlates.list.setData(undefined, (prev) => {
          if (!prev) return undefined;
          return prev.map((p) =>
            p.id === id ? { ...p, status: PlateStatus.Canceled } : p,
          );
        });
      },
    });

  const cancelPlateItem: TableActionItem[] = canCancelPlate
    ? [
        {
          alertDialog: {
            message:
              "Are you sure you want to cancel this plate ? This action cannot be undone.",
            title: "Cancel plate",
          },
          children: "Cancel",
          id: "cancel",
          onClick: () => cancelPlate(id),
          preventDefault: true,
        },
      ]
    : [];

  const items: TableActionItem[] = {
    [PlateStatus.AwaitingQC]: [],
    [PlateStatus.Canceled]: [],
    [PlateStatus.Loaded]: [],
    [PlateStatus.Printing]: [],
    [PlateStatus.QueuedOnInstrument]: [...cancelPlateItem],
    [PlateStatus.Synthesized]: [],
    [PlateStatus.ToBeAssigned]: [...cancelPlateItem],
  }[status];

  if (!items.length) {
    return null;
  }

  return <TableActions items={items} />;
};

const plateColumns: ColumnDef<PlateFromList>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => row.original.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "status",
    cell: ({ row }) => PlateStatusComponent[row.original.status],
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "status",
    meta: {
      title: "Status",
    },
  },
  {
    accessorKey: "instrument",
    cell: ({ row }) =>
      row.original.instrument.id ? (
        <Link
          className="text-blue-500 hover:underline"
          onClick={(e) => e.stopPropagation()}
          to={OrganizationRoutes.INSTRUMENT.replace(
            ":instrumentId",
            row.original.instrument.id,
          )}
        >
          {row.original.instrument.name}
        </Link>
      ) : (
        "-"
      ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "instrument",
    meta: {
      title: "Instrument",
    },
  },
  {
    accessorKey: "filledWells",
    cell: ({ row }) => row.original.filledWells,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "filledWells",
    meta: {
      title: "Filled wells",
    },
  },
  {
    cell: ({ row }) => {
      return <PlateActions plate={row.original} />;
    },
    enableHiding: false,
    header: "Actions",
    id: "actions",
  },
];

export default function Plates() {
  const { data: plates } = trpc.order.productionPlates.list.useQuery();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-3">
      <Heading>Plates</Heading>
      <DataTable
        columns={plateColumns}
        data={plates ?? []}
        onRowClick={(row) => {
          navigate(
            OrganizationRoutes.SERVICE_PLATE.replace(
              ":plateId",
              row.original.id,
            ),
          );
        }}
      />
    </div>
  );
}
