import { ModificationStatus, Purity } from "@console/shared";
import type { LucideProps } from "lucide-react";
import {
  AlertTriangle,
  BanIcon,
  CheckCircleIcon,
  HelpCircle,
} from "lucide-react";
import type react from "react";

export const PurityFlagToIcon: Record<
  Purity,
  react.ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & react.RefAttributes<SVGSVGElement>
  >
> = {
  [Purity.Unknown]: HelpCircle,
  [Purity.Normal]: CheckCircleIcon,
  [Purity.Difficult]: AlertTriangle,
  [Purity.Extreme]: BanIcon,
};

export const PurityFlagToName: Record<Purity, string> = {
  [Purity.Unknown]: "Unknown",
  [Purity.Normal]: "Standard",
  [Purity.Difficult]: "Difficult",
  [Purity.Extreme]: "Extreme",
};

export const PurityFlagToLabel: Record<Purity, string> = {
  [Purity.Unknown]: "Unknown score.",
  [Purity.Normal]:
    "No errors have been found. We do not expect any difficulties printing this.",
  [Purity.Difficult]:
    "The sequence may be more difficult than more common sequences. Proceeding with the sequence may result in reduced yield and/or concentration. Optimizing sequence design is recommended.",
  [Purity.Extreme]:
    "The sequence contains errors. Please click on the sequence to get more details on how to resolve them.",
};

export const ModificationStatusToPosition: Record<ModificationStatus, number> =
  {
    [ModificationStatus.Successful]: 100,
    [ModificationStatus.Acceptable]: 70,
    [ModificationStatus.Low]: 40,
    [ModificationStatus.NonQuantifiable]: 0,
    [ModificationStatus.None]: 0,
  };
