import { OligoStatus } from "@console/shared";
import equal from "fast-deep-equal";
import React from "react";
import { useDrag } from "react-dnd";

import type { OligoFromList } from "./oligos-columns";

import { cn } from "../../../../../lib/utils";
import { DragObjects } from "../../build/new-workflow/types";
import useNewPlateContext from "../hooks/useNewPlateContext";

export type OligoLocalStatus = OligoFromList & {
  isCurrentlyAssigned: boolean;
  isSelected: boolean;
};

const areOligosEqual = (
  prevOligo: OligoLocalStatus,
  nextOligo: OligoLocalStatus,
) => equal(prevOligo, nextOligo);

const OligoRow = React.memo(
  React.forwardRef<
    HTMLTableRowElement,
    React.HTMLAttributes<HTMLTableRowElement> & {
      original: OligoLocalStatus;
    }
  >(({ className, original, onMouseEnter, onMouseLeave, ...props }, ref) => {
    const { selectedOligos } = useNewPlateContext();
    const isOligoSelected = selectedOligos.includes(original.id);
    const { id, status, isSelected, isCurrentlyAssigned } = original;

    const canDrag = status === OligoStatus.Queued && !isCurrentlyAssigned;

    const [collected, drag] = useDrag(
      () => ({
        canDrag,
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
        item: () => {
          if (isOligoSelected) {
            return {
              oligoIds: selectedOligos,
            };
          }
          return {
            oligoId: id,
          };
        },
        type: DragObjects.Oligo,
      }),
      [id, canDrag, isOligoSelected, selectedOligos],
    );

    const onMouseEnterCustom = () => {
      document.querySelectorAll(`[data-group='${id}']`).forEach((el) => {
        el.classList.add("ring");
      });
    };

    const onMouseLeaveCustom = () => {
      document.querySelectorAll(`[data-group='${id}']`).forEach((el) => {
        el.classList.remove("ring");
      });
    };

    return (
      <tr
        className={cn(
          "data-[state=selected]:bg-muted border-b transition-colors hover:ring",
          className,
          canDrag && "cursor-grab",
          collected.isDragging && "bg-slate-400",
          isSelected && "bg-slate-200",
          isCurrentlyAssigned && "opacity-50",
        )}
        data-group={id}
        id={id}
        onMouseEnter={(e) => {
          onMouseEnterCustom();
          onMouseEnter?.(e);
        }}
        onMouseLeave={(e) => {
          onMouseLeaveCustom();
          onMouseLeave?.(e);
        }}
        ref={(node) => {
          drag(node);
          if (typeof ref === "function") {
            ref(node);
          }
        }}
        {...props}
      />
    );
  }),
  (prevProps, nextProps) =>
    areOligosEqual(prevProps.original, nextProps.original) &&
    prevProps.onClick === nextProps.onClick,
);
OligoRow.displayName = "OligoRow";

export default OligoRow;
