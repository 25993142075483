import { InstrumentStatus } from "@console/shared";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

export const statusCircleMap: Record<InstrumentStatus, JSX.Element> = {
  [InstrumentStatus.Available]: (
    <span className="relative flex h-4 w-4">
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75"></span>
      <span className="relative inline-flex h-4 w-4 rounded-full bg-green-500"></span>
    </span>
  ),
  [InstrumentStatus.Busy]: (
    <span className="relative flex h-4 w-4">
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75"></span>
      <span className="relative inline-flex h-4 w-4 rounded-full bg-red-500"></span>
    </span>
  ),
  [InstrumentStatus.Unavailable]: (
    <div className="h-4 w-4 rounded-full bg-gray-500" />
  ),
};

export const statusMap: Record<InstrumentStatus, JSX.Element> = {
  [InstrumentStatus.Available]: (
    <Tooltip>
      <TooltipTrigger asChild>
        {statusCircleMap[InstrumentStatus.Available]}
      </TooltipTrigger>
      <TooltipContent>Available</TooltipContent>
    </Tooltip>
  ),
  [InstrumentStatus.Busy]: (
    <Tooltip>
      <TooltipTrigger asChild>
        {statusCircleMap[InstrumentStatus.Busy]}
      </TooltipTrigger>
      <TooltipContent>Busy</TooltipContent>
    </Tooltip>
  ),
  [InstrumentStatus.Unavailable]: (
    <Tooltip>
      <TooltipTrigger asChild>
        {statusCircleMap[InstrumentStatus.Unavailable]}
      </TooltipTrigger>
      <TooltipContent>Unavailable</TooltipContent>
    </Tooltip>
  ),
};

export const statusMapWithName: Record<InstrumentStatus, JSX.Element> = {
  [InstrumentStatus.Available]: (
    <div className="flex flex-row items-center justify-between space-x-1">
      <span>Available</span>
      {statusCircleMap[InstrumentStatus.Available]}
    </div>
  ),
  [InstrumentStatus.Busy]: (
    <div className="flex flex-row items-center justify-between space-x-1">
      <span>Busy</span>
      {statusCircleMap[InstrumentStatus.Busy]}
    </div>
  ),
  [InstrumentStatus.Unavailable]: (
    <div className="flex flex-row items-center justify-between space-x-1">
      <span>Unavailable</span>
      {statusCircleMap[InstrumentStatus.Unavailable]}
    </div>
  ),
};
