export enum SequenceShippingFormat {
  Plate = "Plate",
  Tube = "Tube",
}

export enum OrderItemType {
  GeneFragment = "Gene Fragment",
  OligoSet = "Oligo set",
}

export enum OrderStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  InFillNFinish = "In fill and finish",
  InProduction = "In production",
  Opportunity = "Opportunity",
  Queued = "Queued",
  Shipping = "Shipping",
}

export enum OrderEvent {
  Archived = "Archived",
  Canceled = "Canceled",
  Opportunity = "Opportunity",
  Queued = "Queued",
  Reopened = "Reopened",
}

export enum AdditionalService {
  GeneFragmentAmplification = "Gene Fragment Amplification",
  NGSSequencing = "NGS Sequencing",
}

export const AdditionalServiceLabel: Record<AdditionalService, string> = {
  [AdditionalService.GeneFragmentAmplification]: "Amplification",
  [AdditionalService.NGSSequencing]: "NGS",
};
