import { ConstructType } from "@console/shared";

import { Badge } from "../../../../../components/ui/badge";

export const CONSTRUCT_TYPE_TO_LABEL: Record<ConstructType, JSX.Element> = {
  [ConstructType.Gene]: (
    <Badge className="bg-secondary text-secondary-foreground">Gene</Badge>
  ),
  [ConstructType.OligoSet]: (
    <Badge className="bg-accent text-accent-foreground">Oligo set</Badge>
  ),
};
