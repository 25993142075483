import { OligoStatus } from "@console/shared";

import { Badge } from "../../../../../components/ui/badge";

export const OligoStatusComponent: Record<OligoStatus, React.ReactNode> = {
  [OligoStatus.Queued]: (
    <Badge className="bg-blue-500">{OligoStatus.Queued}</Badge>
  ),
  [OligoStatus.Assigned]: (
    <Badge className="bg-yellow-500">{OligoStatus.Assigned}</Badge>
  ),
  [OligoStatus.Printing]: (
    <Badge className="bg-gray-500">{OligoStatus.Printing}</Badge>
  ),
  [OligoStatus.Synthesized]: (
    <Badge className="bg-purple-500">{OligoStatus.Synthesized}</Badge>
  ),
  [OligoStatus.Failed]: (
    <Badge className="bg-red-500">{OligoStatus.Failed}</Badge>
  ),
  [OligoStatus.Liberated]: (
    <Badge className="bg-green-500">{OligoStatus.Liberated}</Badge>
  ),
  [OligoStatus.PendingQC]: (
    <Badge className="bg-white">{OligoStatus.PendingQC}</Badge>
  ),
};
