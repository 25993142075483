import { Heading } from "@radix-ui/themes";
import { useParams } from "react-router-dom";

import { PlateStatusComponent } from "./components/plate-status";

import { trpc } from "../../../../config/trpc";
import Plate from "../build/components/plate";
import { PlateActions } from "../build/components/step-actions";

export default function ProductionPlate() {
  const { plateId } = useParams();

  const { data: plate } = trpc.plate.get.useQuery(plateId ?? "", {
    enabled: !!plateId,
  });

  if (!plate) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <Heading className="space-x-2">
          <span>{plate.name}</span>
          {PlateStatusComponent[plate.status]}
        </Heading>
        <PlateActions plate={plate} />
      </div>
      <Plate isEditable={false} plate={plate} />
    </div>
  );
}
