import z from "zod";

export type Direction = "forward" | "reverse complement";

export const defaultUpperTM = 60;
export const defaultLowerTM = 40;

export const assemblyParameters = z.object({
  advanced: z.object({
    add_stubs: z.string().nullable().default(null),
    n_2_design: z.boolean().default(false),
    nb_primers: z.number().min(1).default(1),
    primer_first: z.boolean().default(false),
    smart_block_design: z.boolean().default(false),
    smart_oligo_design: z.boolean().default(false),
    use_error_correction: z.boolean().default(false),
  }),
  lower_tm_threshold: z.number().default(defaultLowerTM),
  name: z.string(),
  oligo_size: z.number().default(30),
  one_block_design: z.boolean().default(false),
  overlap_subblocks: z.number().default(50),
  priority: z.enum(["tm", "length"]).default("tm"),
  smart_design_parameters: z
    .object({
      max_block_overlap_size: z.number(),
      max_block_size: z.number(),
      min_block_overlap_size: z.number(),
      min_block_size: z.number(),
    })
    .optional(),
  subblock_size: z.number().default(300),
  upper_tm_threshold: z.number().default(defaultUpperTM),
});
