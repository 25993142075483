import { AuthorizationOwner, ObjectMembership } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { LucideUnlockKeyhole, Trash } from "lucide-react";
import { Link } from "react-router-dom";

import AddOwner from "../../../components/logic/add-owner";
import { Button } from "../../../components/ui/button";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { SimpleTable } from "../../../components/ui/simple-table";
import { useToast } from "../../../components/ui/use-toast";
import type { OwnersTRPC } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";
import { OrganizationSettingsRoutes } from "../../settings/organization-settings/organization-settings-routes";

const OwnerActions = ({
  withRole,
  objectId,
  ownerType,
  ownerId,
  objectType,
}: {
  objectId: string;
  objectType: ObjectMembership;
  ownerId: string;
  ownerType: AuthorizationOwner;
  withRole: boolean;
}) => {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: removeOwner } =
    trpc.account.authorization.removeOwnerFromObject.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        utils.account.authorization.owners.invalidate({
          id: objectId,
          subjectType: objectType,
        });
        if (ownerType === AuthorizationOwner.Member) {
          utils.account.organizationUser.listWithNoAccessToObject.invalidate();
        } else {
          utils.account.authorization.teamsWithoutAccessToObject.invalidate();
        }
      },
    });

  const handleRemoveOwner = () => {
    removeOwner({
      id: objectId,
      ownerId,
      ownerType,
      subjectType: objectType,
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: withRole
            ? undefined
            : {
                message: "Are you sure you want to remove this owner ?",
                title: "Remove owner",
              },
          children: withRole ? (
            "This owner has global rights, cannot remove ownership"
          ) : (
            <div className="flex items-center gap-2">
              <Trash /> Remove owner
            </div>
          ),
          disabled: withRole,
          id: "Remove owner",
          onClick: handleRemoveOwner,
        },
      ]}
    />
  );
};
const membersColumnHelper = createColumnHelper<OwnersTRPC["members"][0]>();

const useMembersColumn = (subject: ObjectMembership, objectId: string) => {
  return [
    membersColumnHelper.accessor("email", {
      cell: (info) => (
        <Link
          className="text-blue-500 hover:underline"
          to={OrganizationSettingsRoutes.USER.replace(
            ":userId",
            info.row.original.userId,
          )}
        >
          {info.getValue()}
        </Link>
      ),
      header: "Email",
    }),
    membersColumnHelper.display({
      cell: ({ row: { original } }) => (
        <OwnerActions
          objectId={objectId}
          objectType={subject}
          ownerId={original.userId}
          ownerType={AuthorizationOwner.Member}
          withRole={original.withRole}
        />
      ),
      id: "actions",
    }),
  ];
};

const teamsColumnHelper = createColumnHelper<OwnersTRPC["teams"][0]>();

const useTeamsColumn = (subject: ObjectMembership, objectId: string) => {
  return [
    teamsColumnHelper.accessor("name", {
      cell: (info) => (
        <Link
          className="text-blue-500 hover:underline"
          to={OrganizationSettingsRoutes.TEAM.replace(
            ":teamId",
            info.row.original.id,
          )}
        >
          {info.getValue()}
        </Link>
      ),
      header: "Name",
    }),
    teamsColumnHelper.display({
      cell: ({ row: { original } }) => (
        <OwnerActions
          objectId={objectId}
          objectType={subject}
          ownerId={original.id}
          ownerType={AuthorizationOwner.Team}
          withRole={original.withRole}
        />
      ),
      id: "actions",
    }),
  ];
};

const SubjectToObjectName: Record<ObjectMembership, string> = {
  [ObjectMembership.Assay]: "assay",
  [ObjectMembership.Syntax]: "instrument",
  [ObjectMembership.Hamilton]: "hamilton instrument",
  [ObjectMembership.Construct]: "construct",
};

function MembersTable({
  owners,
  objectId,
  subject,
}: {
  objectId: string;
  owners: OwnersTRPC | undefined;
  subject: ObjectMembership;
}) {
  const membersColumn = useMembersColumn(subject, objectId);
  const table = useReactTable({
    columns: membersColumn,
    data: owners?.members ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return <SimpleTable table={table} />;
}

function TeamsTable({
  owners,
  objectId,
  subject,
}: {
  objectId: string;
  owners: OwnersTRPC | undefined;
  subject: ObjectMembership;
}) {
  const teamsColumn = useTeamsColumn(subject, objectId);
  const table = useReactTable({
    columns: teamsColumn,
    data: owners?.teams ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return <SimpleTable table={table} />;
}

export default function OwnersBlock({
  owners,
  objectId,
  subject,
}: {
  objectId: string;
  owners: OwnersTRPC | undefined;
  subject: ObjectMembership;
}) {
  const container = document.getElementById("detail");
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={() => {}}
          type="button"
          variant={"outline"}
        >
          <span>Admin</span>
          <LucideUnlockKeyhole />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="max-h-[90%] min-w-[80%] overflow-scroll"
        container={container || undefined}
      >
        <DialogHeader>
          <DialogTitle>{`Owners of ${subject}`}</DialogTitle>
        </DialogHeader>
        <div className="bg-card space-y-3 rounded-lg border p-4">
          <div className="flex flex-row items-center justify-between">
            <Heading size={"4"}>Owners</Heading>
            <AddOwner
              objectId={objectId}
              objectName={SubjectToObjectName[subject]}
              subject={subject}
            />
          </div>
          <Heading className="ml-3" size={"4"}>
            Members
          </Heading>
          <MembersTable objectId={objectId} owners={owners} subject={subject} />
          <Heading className="ml-3" size={"4"}>
            Teams
          </Heading>
          <TeamsTable objectId={objectId} owners={owners} subject={subject} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
