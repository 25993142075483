import { ObjectMembership } from "@console/shared";
import { Heading } from "@radix-ui/themes";
import { EditIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import OwnersBlock from "./components/owners-block";
import { useGetHamiltonInstrument } from "./useGetInstrument";

import { Button } from "../../components/ui/button";
import { Separator } from "../../components/ui/separator";
import { trpc } from "../../config/trpc";
import { OrganizationRoutes } from "../organization/organization-routes";

export default function HamiltonInstrument() {
  const { data: instrument, isPending } = useGetHamiltonInstrument();
  const navigate = useNavigate();
  const hamiltonId = instrument?.id;

  const { data: instrumentOwners } = trpc.account.authorization.owners.useQuery(
    {
      id: hamiltonId ?? "",
      subjectType: ObjectMembership.Hamilton,
    },
    {
      enabled: !!hamiltonId,
    },
  );

  if (isPending) return <Heading>Loading...</Heading>;

  if (!instrument) {
    return <Heading>Instrument not found</Heading>;
  }

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <Heading className="space-x-2">
          <span>Hamilton instrument</span>
          <span className="italic">{instrument.name}</span>
        </Heading>
        <div className="flex flex-row items-center space-x-2">
          <OwnersBlock
            objectId={instrument.id}
            owners={instrumentOwners}
            subject={ObjectMembership.Hamilton}
          />
          <Button
            onClick={() => {
              navigate(
                OrganizationRoutes.INSTRUMENT_HAMILTON_EDIT.replace(
                  ":instrumentId",
                  hamiltonId ?? "",
                ),
              );
            }}
            type="button"
            variant={"outline"}
          >
            <EditIcon />
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="bg-card col-span-1 space-y-2 rounded-lg border p-4">
          <Heading size={"4"}>Details</Heading>
          {instrument.createdAt && (
            <>
              <div className="flex flex-row justify-between space-x-2">
                <p>Created at</p>
                <p>{new Date(instrument.createdAt).toLocaleString()}</p>
              </div>
              <Separator />
            </>
          )}

          <div className="flex flex-row justify-between space-x-2">
            <p>Name</p>
            <p>{instrument.name}</p>
          </div>
          <Separator />
          <div className="flex flex-row justify-between space-x-2">
            <p>IP address</p>
            <p>{instrument.ipAddress}</p>
          </div>
          <Separator />
          <div className="flex flex-row justify-between space-x-2">
            <p>USB port</p>
            <p>{instrument.usbPort}</p>
          </div>
        </div>
        <div className="bg-card col-span-1 space-y-2 rounded-lg border p-4">
          <Heading size={"4"}>Teach points</Heading>
          <div className="flex flex-row justify-between space-x-2">
            <p>Coming soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
}
