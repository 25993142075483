import { PlateSize } from "@console/shared";
import React from "react";

import { cn } from "../../lib/utils";
import { DISPLAY_COLUMNS_AND_ROWS } from "../../routes/organization/pages/build/components/plate/display";
import {
  getColumns,
  getRows,
} from "../../routes/organization/pages/build/components/useGetAllWellsById";

export default function GenericPlate({
  size,
  wellRenderer,
}: {
  size: PlateSize;
  wellRenderer: (index: string) => React.ReactNode;
}) {
  const columns = getColumns(size);
  const rows = getRows(size);
  const is96 = size === PlateSize.S96;

  return (
    <div
      className={cn(
        `grid-cols-${DISPLAY_COLUMNS_AND_ROWS[size].columns}  grid h-fit grow auto-cols-fr grid-flow-col gap-1 rounded-lg border p-2`,
        is96 ? "text-sm" : "text-xs",
      )}
    >
      <div
        className={`grid-rows-${DISPLAY_COLUMNS_AND_ROWS[size].rows} grid auto-rows-fr gap-1`}
      >
        <div />
        {rows.map((r) => (
          <div className="flex items-center justify-center" key={r}>
            {r}
          </div>
        ))}
      </div>
      {columns.map((column) => {
        return (
          <div
            className={cn(
              `grid-rows-${DISPLAY_COLUMNS_AND_ROWS[size].rows} grid auto-rows-fr gap-1 rounded-lg`,
            )}
            key={column}
          >
            <div
              className="flex items-center justify-center"
              key="column-header"
            >
              {column}
            </div>
            {rows.map((row) => {
              const index = `${row}${column}`;
              return (
                <React.Fragment key={index}>
                  {wellRenderer(index)}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
