import { StepType } from "@console/shared";
import { Link } from "react-router-dom";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";

import { NODE_EDGE_SIZE } from "./getWorkflowGraph";
import type { NodeData } from "./types";

import { KitLabel } from "../../../../../../components/logic/kit-label";
import { Badge } from "../../../../../../components/ui/badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../../../components/ui/card";
import { Progress } from "../../../../../../components/ui/progress";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";
import { CheckIfTrueElseWarn } from "../../../../../admin/organizations/components/ui";
import { RunStateToBadge } from "../../../../../instrument/components/runs-table";
import { OrganizationRoutes } from "../../../../organization-routes";

export default function WorkflowStepNode({ data }: NodeProps<NodeData>) {
  const { assayId, completion, id, name, needs, plate, type } = data;
  const filledWells = plate?.filledWells;
  const successRate = plate?.successRate;

  const needsSomething = needs.length > 0;
  const isNeededBySomething = data.neededBy.length > 0;
  const isPlateSynthesis = type === StepType.OligoSynthesis;
  const runStatus = plate?.status;
  const plateHasErrors = Boolean(plate?.hasErrors);

  return (
    <div>
      {needsSomething && (
        <Handle id="left" position={Position.Left} type="target" />
      )}
      <Link
        to={OrganizationRoutes.ASSAY_WORKFLOW_STEP.replace(
          ":assayId",
          assayId,
        ).replace(":stepId", id)}
      >
        <Card
          className={`h-[${NODE_EDGE_SIZE}px] w-[${NODE_EDGE_SIZE}px] outline-2 outline-offset-2 hover:outline`}
        >
          <CardHeader>
            <CardTitle className={`flex flex-row items-center justify-between`}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p
                    aria-label={`View step ${name}`}
                    className={`flex grow flex-row items-center space-x-3 truncate text-xl`}
                  >
                    {name}
                  </p>
                </TooltipTrigger>
                <TooltipContent>
                  {isPlateSynthesis ? name : "View the hamilton step"}
                </TooltipContent>
              </Tooltip>
            </CardTitle>
            <CardDescription className="flex flex-row items-center justify-between">
              <span>
                {isPlateSynthesis
                  ? "Synthesize a plate on the Syntax"
                  : "Oligos assembly"}
              </span>
            </CardDescription>
          </CardHeader>
          <CardContent className="flex w-full flex-col space-y-2">
            {isPlateSynthesis && (
              <>
                <div className="flex justify-between">
                  {runStatus && <p>{RunStateToBadge[runStatus]}</p>}
                  <p className="flex flex-row items-center space-x-1">
                    <CheckIfTrueElseWarn value={!plateHasErrors} />
                    <span>
                      {plateHasErrors ? "Plate has errors" : "Plate is valid"}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="text-muted-foreground text-sm">{`Progress ${Math.round(completion)}%`}</p>
                  <Progress
                    indicatorClassName="bg-green-400"
                    value={completion}
                  />
                </div>
                {successRate && (
                  <div>
                    <p className="text-muted-foreground text-sm">{`Success rate ${Math.round(successRate * 100)}%`}</p>
                    <Progress
                      indicatorClassName="bg-green-600"
                      value={successRate * 100}
                    />
                  </div>
                )}
                <div className="flex h-full flex-row justify-self-end">
                  <KitLabel kitId={plate?.kit ?? ""} />
                  <div className="flex flex-wrap">
                    <Badge className="m-1" variant={"outline"}>
                      <span>{filledWells} wells</span>
                    </Badge>
                    <Badge className="m-1" variant={"outline"}>
                      <span>{plate?.cycles} cycles</span>
                    </Badge>
                  </div>
                </div>
              </>
            )}
          </CardContent>
          <CardFooter className="nodrag flex justify-end"></CardFooter>
        </Card>
      </Link>
      {isNeededBySomething && (
        <Handle id="right" position={Position.Right} type="source" />
      )}
    </div>
  );
}
