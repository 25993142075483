import type React from "react";
import {
  createContext,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";

import { trpc } from "../../../../../config/trpc";
import {
  newPlateReducer,
  type NewPlate,
  type NewPlateReducerActions,
} from "../components/new-production-plate.reducer";
import type { OligoLocalStatus } from "../components/oligo-row";

const PastelColors = [
  "#FFD1DC",
  "#AEC6CF",
  "#77DD77",
  "#FDFD96",
  "#CFCFFF",
  "#FFB347",
  "#FFDAB9",
  "#AAF0D1",
  "#DABFFF",
  "#B2EBF2",
];

export type NewPlateContextType = {
  dispatchPlateChange: React.Dispatch<NewPlateReducerActions>;
  getOligoBgColor: (oligoId: string) => string | undefined;
  getOligoById: (oligoId: string) => OligoLocalStatus | undefined;
  newPlate: NewPlate;
  oligos: OligoLocalStatus[];
  orderColors: Map<string, string>;
  selectedOligos: string[];
  setSelectedOligos: React.Dispatch<React.SetStateAction<string[]>>;
};

export const NewPlateContext = createContext<NewPlateContextType>({
  dispatchPlateChange: () => {},
  getOligoBgColor: () => undefined,
  getOligoById: () => undefined,
  newPlate: null,
  oligos: [],
  orderColors: new Map(),
  selectedOligos: [],
  setSelectedOligos: () => {},
});

export const NewPlateProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const [selectedOligos, setSelectedOligos] = useState<string[]>([]);
  const [newPlate, dispatchPlateChange] = useReducer(newPlateReducer, null);
  const { data: fetchedOligos } = trpc.order.oligos.useQuery(undefined, {
    initialData: [],
  });
  const assignedOligos = useMemo(
    () => newPlate?.wells.map((w) => w.oligoId) ?? [],
    [newPlate?.wells],
  );
  const oligos: OligoLocalStatus[] = useMemo(
    () =>
      fetchedOligos.map(
        (oligo) => ({
          ...oligo,
          isCurrentlyAssigned: assignedOligos.includes(oligo.id),
          isSelected: selectedOligos.includes(oligo.id),
        }),
        [],
      ) ?? [],
    [fetchedOligos, assignedOligos, selectedOligos],
  );

  const orderColors = useMemo(() => {
    const orderIds = Array.from(new Set(oligos.map((o) => o.orderSOId)));
    const colors = new Map<string, string>();
    orderIds.forEach((id, index) => {
      if (!id) {
        return;
      }
      colors.set(id, PastelColors[index % PastelColors.length]);
    });
    return colors;
  }, [oligos]);

  const oligosById = useMemo(
    () => new Map(oligos.map((o) => [o.id, o])),
    [oligos],
  );

  const getOligoBgColor = useCallback(
    (oligoId: string) => {
      const oligo = oligosById.get(oligoId);
      if (!oligo || !oligo?.orderSOId) {
        return undefined;
      }
      return orderColors.get(oligo.orderSOId);
    },
    [oligosById, orderColors],
  );

  const getOligoById = useCallback(
    (oligoId: string) => oligosById.get(oligoId),
    [oligosById],
  );

  const value = useMemo(() => {
    return {
      dispatchPlateChange,
      getOligoBgColor,
      getOligoById,
      newPlate,
      oligos: oligos,
      orderColors,
      selectedOligos,
      setSelectedOligos,
    };
  }, [
    getOligoById,
    getOligoBgColor,
    newPlate,
    oligos,
    orderColors,
    selectedOligos,
  ]);

  return (
    <NewPlateContext.Provider value={value}>
      {children}
    </NewPlateContext.Provider>
  );
};
