import type { PlateErrorSeverity } from "@console/shared";
import {
  PlateErrorType,
  WellErrorSeverity,
  WellErrorType,
} from "@console/shared";

import type { PlateFromWorkflow } from "./plate/types";

type PlateProblem = {
  message: string;
} & (
  | {
      isPlate: true;
      severity: PlateErrorSeverity;
      type: PlateErrorType;
    }
  | {
      isPlate: false;
      severity: WellErrorSeverity;
      type: WellErrorType;
    }
);

const ErrorTypeToLabel: Record<PlateErrorType | WellErrorType, string> = {
  [PlateErrorType.Modifiers]: "Click",
  [PlateErrorType.Biosecurity]: "Biosecurity",
  [PlateErrorType.Cycles]: "Maximum cycles",
  [WellErrorType.Click]: "Click",
  [WellErrorType.Length]: "Length",
  [WellErrorType.Purity]: "Purity",
  [WellErrorType.UnknownNucleotide]: "UnknownNucleotide",
};

export const getPlateProblems = (plate: PlateFromWorkflow): PlateProblem[] => {
  return [
    ...plate.errors.map((e) => ({
      isPlate: true as const,
      message: `Plate error | ${ErrorTypeToLabel[e.type]} | ${e.message}`,
      severity: e.severity,
      type: e.type,
    })),
    ...plate.wells
      .map((well) => ({
        errors: well.errors.filter(
          (e) => e.severity === WellErrorSeverity.Error,
        ),
        well,
      }))
      .filter((w) => w.errors.length > 0)
      .map(({ well, errors }) =>
        errors.map((e) => ({
          isPlate: false as const,
          message: `Well ${well.index} error | ${ErrorTypeToLabel[e.type]} | ${e.message}`,
          severity: e.severity,
          type: e.type,
        })),
      )
      .flat(),
  ];
};
