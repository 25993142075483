import { zodResolver } from "@hookform/resolvers/zod";
import { DialogDescription } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../../../components/ui/button";
import { DatePicker } from "../../../../../components/ui/datepicker";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

const queueOrderFormSchema = z.object({
  expectedCompletionDate: z.date(),
  netsuiteSOId: z.string(),
});

export type QueueOrderFormType = z.infer<typeof queueOrderFormSchema>;

export default function QueueOrderForm({ id }: { id: string }) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: queueOrder } = trpc.order.queue.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
      setOpen(false);
    },
    onSuccess() {
      utils.order.list.invalidate();
      setOpen(false);
    },
  });

  const form = useForm<QueueOrderFormType>({
    resolver: zodResolver(queueOrderFormSchema),
  });

  const handleQueueOrder = (data: QueueOrderFormType) => {
    queueOrder({
      expectedCompletionDate: data.expectedCompletionDate.toISOString(),
      id,
      netsuiteSOId: data.netsuiteSOId,
    });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <span>Queue</span>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <Form {...form}>
            <form
              className="space-y-4"
              onSubmit={form.handleSubmit(handleQueueOrder)}
            >
              <DialogHeader>
                <DialogTitle>Queue order</DialogTitle>
                <DialogDescription>
                  Please provide the expected completion date and the Netsuite
                  Sales Order ID.
                </DialogDescription>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="netsuiteSOId"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Netsuite Sales Order ID</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="expectedCompletionDate"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormControl>
                          <DatePicker
                            date={field.value}
                            options={{ disabled: { before: new Date() } }}
                            placeholder="Pick expected completion date"
                            preventFocusOutside
                            setDate={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <DialogFooter>
                  <Button
                    onClick={() => setOpen(false)}
                    type="button"
                    variant={"outline"}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => handleQueueOrder(form.getValues())}>
                    Submit
                  </Button>
                </DialogFooter>
              </DialogHeader>
            </form>
          </Form>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
